export const FAQ_CREATE_REQUEST ='FAQ_CREATE_REQUEST'
export const FAQ_CREATE_SUCCESS ='FAQ_CREATE_SUCCESS'
export const FAQ_CREATE_FAIL ='FAQ_CREATE_FAIL'
export const FAQ_CREATE_RESET ='FAQ_CREATE_RESET'


export const FETCH_FAQ_REQUEST ='FETCH_FAQ_REQUEST'
export const FETCH_FAQ_SUCCESS ='FETCH_FAQ_SUCCESS'
export const FETCH_FAQ_FAIL ='FETCH_FAQ_FAIL'

export const DELETE_FAQ_REQUEST ='DELETE_FAQ_REQUEST'
export const DELETE_FAQ_SUCCESS ='DELETE_FAQ_SUCCESS'
export const DELETE_FAQ_FAIL ='DELETE_FAQ_FAIL'
export const DELETE_FAQ_RESET='DELETE_FAQ_RESET'


// Constants for fetching a FAQ by ID
export const FETCH_FAQ_DETAILS_REQUEST = "FETCH_FAQ_DETAILS_REQUEST";
export const FETCH_FAQ_DETAILS_SUCCESS = "FETCH_FAQ_DETAILS_SUCCESS";
export const FETCH_FAQ_DETAILS_FAIL = "FETCH_FAQ_DETAILS_FAIL";
export const FETCH_FAQ_DETAILS_RESET = "FETCH_FAQ_DETAILS_RESET";

// Constants for editing a FAQ
export const EDIT_FAQ_REQUEST = "EDIT_FAQ_REQUEST";
export const EDIT_FAQ_SUCCESS = "EDIT_FAQ_SUCCESS";
export const EDIT_FAQ_FAIL = "EDIT_FAQ_FAIL";
export const EDIT_FAQ_RESET='EDIT_FAQ_RESET'


