import React, { useEffect, useState } from "react";
import { Box, GridItem, Grid } from "@chakra-ui/react";
import styles from "../styles/Dashboard.module.css";
import {
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
import { Textarea } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { addReview, editReviewAction, getReview, getReviewById } from "../actions/ReviewAction";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import AdminNavbar from "../components/AdminNavbar";
import { EDIT_REVIEW_RESET, REVIEW_CREATE_RESET } from "../constants/Review";
import { showSuccessNotification } from "../components/PopupBox";

function AddReview() {
    const [title, setTitle] = useState('')
    const [name, setName] = useState('')
    const [place, setPlace] = useState('')
    const [review, setReview] = useState('')
    const [page, setPage] = useState('')
    const getReviews=useSelector(state=>state.fetchReview)
    const {loading,error,reviews,data}=getReviews
    const dispatch=useDispatch()
    const createReview=useSelector(state=>state.createReview)
    const {success}=createReview
    const navigate=useNavigate()
    const { id } = useParams();
    const editReview=useSelector(state=>state.editReview)
    const {success:successEdit}=editReview


    const submitHandler=(e)=>{
      e.preventDefault()
      if(!id){
      dispatch(addReview(title,name,place,review,page))
    }else{
      const data={
        review_id:id,
        title,
        name,
        place,
        data:review,
        page,
      }
      dispatch(editReviewAction(data))
    }
    }

    useEffect(()=>{
      
      if(id){
        dispatch(getReviewById(id))
        
        
   
      }
      
    },[])

    useEffect(()=>{
      if(data){
     
        setTitle(reviews.data.title)
        setName(reviews.data.name)
        setPlace(reviews?.data?.place)
        setPage(reviews?.data?.page)
        setReview(reviews?.data?.data)
        console.log('hey');
      }

    },[data,id])

    
  useEffect(() => {
    if (success) {
      showSuccessNotification('Review created Successfully.');
      dispatch({ type: REVIEW_CREATE_RESET });
      navigate('/review');
    }
    if (successEdit) {
      showSuccessNotification('Review Edited Successfully.');
      dispatch({ type: EDIT_REVIEW_RESET });
      navigate('/review');
    }
  }, [success, successEdit]);
  
  return (
    <div >
      <AdminNavbar>
      <Box as="section"  className='review'>
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <form onSubmit={submitHandler}>
              <FormLabel>Title</FormLabel>
              <Input type='text'placeholder="Title" value={title}  onChange={(e)=>setTitle(e.target.value)} required/>
             
              <FormLabel>Name</FormLabel>
              <Input placeholder="Name" value={name} onChange={(e)=>setName(e.target.value)} required/>
              <FormLabel>Place</FormLabel>
              <Input placeholder="Place" value={place} onChange={(e)=>setPlace(e.target.value)} required/>
              <FormLabel>Page</FormLabel>
              <select onChange={(e)=>setPage(e.target.value)} style={{width:"100%", border:"1px solid" , borderColor:"inherit" , padding:"10px 10px" , borderRadius:"5px"}} >
              
              {page && (<option  >{ page}</option>)}
                <option selected >All Pages</option>
                <option>Spa</option>
                <option>Ahlan</option>
                <option>Lounges</option>
                <option>Restaurents</option>
                <option>Rooms</option>
              </select>
              <FormLabel>Review</FormLabel>
              <Textarea  placeholder="Review" value={review} onChange={(e)=>setReview(e.target.value)} required/>
              <button  type="submit">submit</button>
            </form>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  );
}

export default AddReview;
