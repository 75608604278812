import React, { useEffect, useState } from "react";
import styles from "../styles/Admin.module.css";
import { BsCartPlus } from "react-icons/bs";
import { BiHome } from "react-icons/bi";
import { TfiGallery, TfiLayoutSlider } from "react-icons/tfi";
import { MdOutlineRateReview, MdOutlineQuestionAnswer } from "react-icons/md";
// import {} from 'react-icons/fa'
import { BiSpa } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { MdEventSeat } from "react-icons/md";
import { ImPriceTags } from "react-icons/im";

import {
  Box,
  Input,
  InputGroup,
  useMediaQuery,
  Image,
  InputLeftElement,
  Button,
} from "@chakra-ui/react";
import { BiMenu, BiSearch } from "react-icons/bi";
import { ImBlog } from "react-icons/im";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminSignout } from "../actions/LoginAction";

const AdminNavbar = ({ children }) => {
  const [isSmallerThan1024] = useMediaQuery("(max-width: 1024px)");
  const adminsignin = useSelector((state) => state.adminSignin);
  const { adminInfo } = adminsignin;
  const dispatch = useDispatch();

  const [navToggle, setNavToggle] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (isSmallerThan1024) {
      setNavToggle(false);
    }
  }, [isSmallerThan1024]);

  const signoutHandler = () => {
    dispatch(adminSignout());
  };

  return (
    <>
      <Box
        as="nav"
        className={`${styles.navigation} ${navToggle && styles.navActive}`}
      >
        <ul>
          <li style={{ paddingTop: "15px" }}>
            <Link to="/admin">
              <a>
                <img
                  src="/assets/images/newlogo.png"
                  width={"40px"}
                  height={"32.2px"}
                />
                <span className={styles.brandName}>DIH Dashboard</span>
              </a>
            </Link>
          </li>
          {/* <li className={location.pathname == "/" && styles.adminNavActive}>
            <Link to="/">
              <a>
                <BiHome />
                <span>Dashboard</span>
              </a>
            </Link>
          </li> */}
          <li
            className={
              location.pathname == "/gallery"
                ? styles.adminNavActive
                : location.pathname == "/add-gallery"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/gallery">
              <a>
                <TfiGallery />
                <span>Gallery</span>
              </a>
            </Link>
          </li>

          <li
            className={
              location.pathname == "/blog"
                ? styles.adminNavActive
                : location.pathname == "/add-blog"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/blog">
              <a>
                <ImBlog />

                <span>Blog</span>
              </a>
            </Link>
          </li>

          <li
            className={
              location.pathname === `/review`
                ? styles.adminNavActive
                : location.pathname === "/add-review"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/review">
              <a>
                <MdOutlineRateReview />

                <span>Review</span>
              </a>
            </Link>
          </li>

          <li
            className={
              location.pathname === `/banner`
                ? styles.adminNavActive
                : location.pathname === "/add-banner"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/banner">
              <a>
                <TfiGallery />

                <span>Banner</span>
              </a>
            </Link>
          </li>

          <li
            className={
              location.pathname === `/timerestriction`
                ? styles.adminNavActive
                : location.pathname === "/add-timerestriction"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/timerestriction">
              <a>
                <TfiGallery />

                <span>Time Restriction</span>
              </a>
            </Link>
          </li>

          <li
            className={
              location.pathname === `/homeslider`
                ? styles.adminNavActive
                : location.pathname === "/add-homeslider"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/homeslider">
              <a>
                <TfiLayoutSlider />

                <span>Home Sliders</span>
              </a>
            </Link>
          </li>

          <li
            className={
              location.pathname === `/faq`
                ? styles.adminNavActive
                : location.pathname === "/add-faq"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/faq">
              <a>
                <MdOutlineQuestionAnswer />

                <span>FAQ</span>
              </a>
            </Link>
          </li>

          <li
            className={
              location.pathname === `/from`
                ? styles.adminNavActive
                : location.pathname === "/form"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/form">
              <a>
                <MdOutlineQuestionAnswer />

                <span>Form</span>
              </a>
            </Link>
          </li>

          <li
            className={
              location.pathname === `/spa`
                ? styles.adminNavActive
                : location.pathname === "/spa"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/spa">
              <a>
                <BiSpa />

                <span>SPA</span>
              </a>
            </Link>
          </li>
          <li
            className={
              location.pathname === `/booked-user`
                ? styles.adminNavActive
                : location.pathname === "/booked-user"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/booked-user">
              <a>
                <FaUsers />

                <span>SPA User</span>
              </a>
            </Link>
          </li>

          <li
            className={
              location.pathname === `/ahlan`
                ? styles.adminNavActive
                : location.pathname === "/ahlan"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/ahlan">
              <a>
                <BiSpa />

                <span>Ahlan Meet & Greet</span>
              </a>
            </Link>
          </li>
          <li
            className={
              location.pathname === `/ahlan-user`
                ? styles.adminNavActive
                : location.pathname === "/ahlan-user"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/ahlan-user">
              <a>
                <FaUsers />

                <span>Ahlan Meet & Greet User</span>
              </a>
            </Link>
          </li>

          <li
            className={
              location.pathname === `/ahlan-lounge`
                ? styles.adminNavActive
                : location.pathname === "/ahlan-lounge"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/ahlan-lounge">
              <a>
                <MdEventSeat />

                <span>Ahlan Lounge</span>
              </a>
            </Link>
          </li>
          <li
            className={
              location.pathname === `/ahlan-lounge-user`
                ? styles.adminNavActive
                : location.pathname === "/ahlan-lounge-user"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/ahlan-lounge-user">
              <a>
                <FaUsers />

                <span>Ahlan Lounge User</span>
              </a>
            </Link>
          </li>
          <li
            className={
              location.pathname === `/tier`
                ? styles.adminNavActive
                : location.pathname === "/tier"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/tier">
              <a>
                <ImPriceTags />

                <span>Tier</span>
              </a>
            </Link>
          </li>
          <li
            className={
              location.pathname === `/users`
                ? styles.adminNavActive
                : location.pathname === "/users"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/users">
              <a>
                <FaUsers />

                <span>Users</span>
              </a>
            </Link>
          </li>
          <li
            className={
              location.pathname === `/travelagency-users`
                ? styles.adminNavActive
                : location.pathname === "/travelagency-users"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/travelagency-users">
              <a>
                <FaUsers />

                <span>Travel Agency Users</span>
              </a>
            </Link>
          </li>
          <li
            className={
              location.pathname === `/disabled-users`
                ? styles.adminNavActive
                : location.pathname === "/disabled-users"
                ? styles.adminNavActive
                : ""
            }
          >
            <Link to="/disabled-users">
              <a>
                <FaUsers />

                <span>Disabled Users</span>
              </a>
            </Link>
          </li>
        </ul>
      </Box>
      <Box className={`${styles.main} ${navToggle && styles.mainActive}`}>
        <Box className={styles.topbar}>
          <Box className={styles.toggle}>
            <BiMenu
              onClick={() => {
                setNavToggle(!navToggle);
              }}
            />
          </Box>
          <Box className={styles.search}>
            {/* <InputGroup className={styles.searchInpGrp}>
              <InputLeftElement pointerEvents="none">
                <BiSearch
                  style={{
                    height: "25px",
                    width: "25px",
                    top: "7px",
                    marginLeft: "10px",
                  }}
                />
              </InputLeftElement>
              <Input type="text" placeholder="Search here" />
            </InputGroup> */}
          </Box>
          <Box className={styles.user}>
            {adminInfo && <Button onClick={signoutHandler}>Signout</Button>}
          </Box>
        </Box>
        {children}
      </Box>
    </>
  );
};

export default AdminNavbar;
