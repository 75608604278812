
import React, { useEffect, useState } from 'react'
import {
  Box,
  Heading,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import styles from "../styles/Dashboard.module.css";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';

import Swal from 'sweetalert2';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import AdminNavbar from '../components/AdminNavbar';
import { Url } from '../constants/Spa';
import axios from 'axios';
import { showSuccessNotification } from '../components/PopupBox';

function TimeRestrictionScreen() {

    const [data, setData] = useState([])
 
  const dispatch=useDispatch()

  const navigate = useNavigate()
//   useEffect(()=>{
//     dispatch({type:FETCH_AHLAN_DETAILS_RESET})
//     if(success){
//       dispatch({type:AHLAN_CREATE_RESET})
     
//     }
//     if(successDelete){
//       dispatch({type:DELETE_AHLAN_RESET})
//       Swal.fire({
//         title: 'Ahlan deleted Succesfully.',
//         text: "Thanks",
//         type: 'success',    
//         icon: 'success',        
//       }); 
//     }
//     dispatch(getAhlan())
//   },[success,successDelete])



 

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };


  const callApi=()=>{
    axios.get(`${Url}/api/v1/time-restriction`).then((responnse)=>{
      console.log(responnse.data.data,'responnse')
      setData(responnse.data.data)
    })
  }
  useEffect(()=>{
    callApi()
   
  },[])

  const deleteHandler=(id)=>{
    axios.delete(`${Url}/api/v1/time-restriction/${id}`).then((response)=>{
        showSuccessNotification('Time Restriction Deleted Successfully')
        callApi()
    }).catch((error)=>{
        console.log(error)
    })
  }
  return (
    <div>
      <AdminNavbar>
      <Box as="section">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <Box className={styles.cardHeader}>
              <Heading>TimeRestriction Details</Heading>
              <Link to={"/add-timerestriction"}>
                <a className={styles.adminBtn}>Add TimeRestriction</a>
              </Link>
            </Box>
            <TableContainer className={styles.tableContainer}>
              <Table variant="simple" size="lg">
                <Thead>
                  <Tr>
                    <Th style={{width:'20%'}}>From</Th>
                    <Th style={{width:'40%'}}>To</Th>
                    <Th style={{width:'40%'}}>Services</Th>
                    {/* <Th style={{width:'40%'}}>Service</Th>
                    <Th style={{width:'8%'}}>Action</Th> */}
                  </Tr>
                </Thead>
                
           

              {data &&  data.map((fq)=>(
                  <Tbody key={fq._id}>
                  <Tr>
                  <Td>{new Date(fq.from).toLocaleString()}</Td>
                    <Td>{new Date(fq.to).toLocaleString()}</Td>
                    {/* <Td>{fq.ahlan}</Td>//array need to display */}
                    <Td>
        {`${fq.ahlan.map(item => item.title).join(', ')}, ${fq.spa.map(item => item.serviceTitle).join(', ')}, ${fq.ahlan_lounge.map(item => item.title).join(', ')}`}
       </Td>
                    <Td>
                    <span className={styles.inProgress} style={{cursor:'pointer'}} onClick={() => {navigate(`/edit-timerestriction/${fq._id}`);}}>Edit</span>
                      <span className={styles.inProgress} style={{cursor:'pointer'}} onClick={()=>deleteHandler(fq._id)}>Delete</span>
                      
                    </Td>
                  
                  </Tr>
                </Tbody>
                ))}
                
              </Table>
            </TableContainer>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  )
}

export default TimeRestrictionScreen