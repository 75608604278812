import {
  SPA_CREATE_FAIL,
  SPA_CREATE_REQUEST,
  SPA_CREATE_RESET,
  SPA_CREATE_SUCCESS,
  DELETE_SPA_FAIL,
  DELETE_SPA_REQUEST,
  DELETE_SPA_RESET,
  DELETE_SPA_SUCCESS,
  FETCH_SPA_FAIL,
  FETCH_SPA_REQUEST,
  FETCH_SPA_SUCCESS,
  EDIT_SPA_REQUEST,
  EDIT_SPA_SUCCESS,
  EDIT_SPA_FAIL,
  EDIT_SPA_RESET,
  FETCH_SPA_DETAILS_REQUEST,
  FETCH_SPA_DETAILS_SUCCESS,
  FETCH_SPA_DETAILS_FAIL,
  FETCH_SPA_DETAILS_RESET,
} from "../constants/Spa";

export const createSpaReducer = (state = {}, action) => {
  switch (action.type) {
    case SPA_CREATE_REQUEST:
      return { loading: true };
    case SPA_CREATE_SUCCESS:
      return { loading: false, success: true, response: action.payload };
    case SPA_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case SPA_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const getSpasReducer = (state = { loading: true, spas: [] }, action) => {
  switch (action.type) {
    case FETCH_SPA_REQUEST:
      return { loading: true };
    case FETCH_SPA_SUCCESS:
      return { loading: false, spas: action.payload.data };
    case FETCH_SPA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteSpaReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_SPA_REQUEST:
      return { loading: true };
    case DELETE_SPA_SUCCESS:
      return { loading: false, success: true, response: action.payload };
    case DELETE_SPA_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_SPA_RESET:
      return {};
    default:
      return state;
  }
};



export const editSpaReducer = (
  state = { loading: true, success: false },
  action
) => {
  switch (action.type) {
    case EDIT_SPA_REQUEST:
      return { ...state, loading: true };
    case EDIT_SPA_SUCCESS:
      return { loading: false, success: true };
    case EDIT_SPA_FAIL:
      return { loading: false, error: action.payload };
      case EDIT_SPA_RESET:
        return {}
    default:
      return state;
  }
};


export const fetchSpaReducer = (
  state = { loading: true, spas: [] },
  action
) => {
  switch (action.type) {
    case FETCH_SPA_DETAILS_REQUEST:
      return { loading: true };
    case FETCH_SPA_DETAILS_SUCCESS:
      return { loading: false, spas: action.payload ,data:true};
    case FETCH_SPA_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case FETCH_SPA_DETAILS_RESET:
      return {}
    default:
      return state;
  }
};