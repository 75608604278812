import axios from "axios";
import {
  AHLAN_CREATE_FAIL,
  AHLAN_CREATE_REQUEST,
  AHLAN_CREATE_SUCCESS,
  DELETE_AHLAN_FAIL,
  DELETE_AHLAN_REQUEST,
  DELETE_AHLAN_SUCCESS,
  EDIT_AHLAN_FAIL,
  EDIT_AHLAN_REQUEST,
  EDIT_AHLAN_SUCCESS,
  FETCH_AHLAN_DETAILS_FAIL,
  FETCH_AHLAN_DETAILS_REQUEST,
  FETCH_AHLAN_DETAILS_SUCCESS,
  FETCH_AHLAN_FAIL,
  FETCH_AHLAN_REQUEST,
  FETCH_AHLAN_SUCCESS,
  Url,
} from "../constants/Ahlan";

export const addAhlan =
  (
    title,
    image,
    service,
    terminal,
    description,
    adult,
    extra,
    group,
    children,
    note
  ) =>
  async (dispatch) => {
    dispatch({ type: AHLAN_CREATE_REQUEST });
    try {
      const { data } = await axios.post(
        `${Url}/api/v1/ahlan`,
        {
          title,
          image,
          service,
          terminal,
          description,
          adult,
          extra,
          group,
          children,
          note,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      dispatch({ type: AHLAN_CREATE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: AHLAN_CREATE_FAIL, payload: message });
    }
  };

export const getAhlan = () => async (dispatch) => {
  dispatch({ type: FETCH_AHLAN_REQUEST });
  try {
    const { data } = await axios.get(`${Url}/api/v1/ahlan`);
    dispatch({ type: FETCH_AHLAN_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: FETCH_AHLAN_FAIL, payload: message });
  }
};

export const deleteAhlan = (id) => async (dispatch) => {
  dispatch({ type: DELETE_AHLAN_REQUEST });
  try {
    const { data } = await axios.delete(`${Url}/api/v1/ahlan/${id}`);
    dispatch({ type: DELETE_AHLAN_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: DELETE_AHLAN_FAIL, payload: message });
  }
};

export const getAhlanById = (id) => async (dispatch) => {
  dispatch({ type: FETCH_AHLAN_DETAILS_REQUEST });
  try {
    const { data } = await axios.get(`${Url}/api/v1/ahlan/${id}`);
    dispatch({ type: FETCH_AHLAN_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: FETCH_AHLAN_DETAILS_FAIL, payload: message });
  }
};

export const editAhlanAction = (ahlan) => async (dispatch) => {
  dispatch({ type: EDIT_AHLAN_REQUEST });

  try {
    const { data } = await axios.put(`${Url}/api/v1/ahlan`, ahlan);
    dispatch({ type: EDIT_AHLAN_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: EDIT_AHLAN_FAIL, payload: message });
  }
};
