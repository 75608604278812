import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  Table,
  TableContainer,
} from "@chakra-ui/react";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AdminNavbar from "../components/AdminNavbar";
import { getAhlan } from "../actions/AhlanAction";
import { getSpa } from "../actions/SpaAction";
import { getAhlanLounge } from "../actions/AhlanLoungeAction";
import styles from "../styles/Dashboard.module.css";
import axios from "axios";
import { Url } from "../constants/Spa";
import { showSuccessNotification } from "../components/PopupBox";
import { useNavigate, useParams } from "react-router-dom";

function AddTimeRestrictionScreen() {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [spaSelected, setspaSelected] = useState([]);
  const [ahlanSelected, setAhlanSelected] = useState([]);
  const { id } = useParams();

  const getAhlans = useSelector((state) => state.getAhlans);
  const { loading: loadingAhlan, error: errorAhlan, ahlans } = getAhlans;

  const getAhlanLoungeState = useSelector((state) => state.getAhlanLoungeState);
  const {
    loading: loadingAhlanLounge,
    error: errorAhlanLounge,
    ahlanLoungeState,
  } = getAhlanLoungeState;

  const getSpas = useSelector((state) => state.getSpas);
  const { loading: loadingSpa, error: errorSpa, spas } = getSpas;
  const navigate = useNavigate();

  const handleSave = () => {
    const formattedStartDate =
      startDate instanceof Date ? startDate.toISOString() : startDate;
    const formattedEndDate =
      endDate instanceof Date ? endDate.toISOString() : endDate;
    const data = {
      from: formattedStartDate,
      to: formattedEndDate,
    };
    if (selectedItems.length > 0) {
      data.ahlan_lounge = selectedItems;
    }
    if (ahlanSelected.length > 0) {
      data.ahlan = ahlanSelected;
    }
    if (spaSelected.length > 0) {
      data.spa = spaSelected;
    }
    if (!id) {
      if (formattedStartDate && formattedEndDate) {
        axios
          .post(`${Url}/api/v1/time-restriction`, data)
          .then((response) => {
            console.log(response);
            showSuccessNotification("Time Restriction Created Successfully");
            navigate("/timerestriction");
          })
          .catch((error) => {
            console.log(error);
          });
        console.log(data, "data");
        // Send the data and selected items to the backend as needed
      } else {
        console.log("Please select both start and end dates.");
      }
    } else {
      data.tr_id = id;
      console.log(data);

      axios
        .put(`${Url}/api/v1/time-restriction`, data)
        .then((response) => {
          console.log(response);
          showSuccessNotification("Time Restriction Updated Successfully");
          navigate("/timerestriction");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (id) {
      axios.get(`${Url}/api/v1/time-restriction/${id}`).then((response) => {
        const ahlan = response.data.data.ahlan.map((item) => item._id);
        const spa = response.data.data.spa.map((item) => item._id);
        const ahlan_lounge = response.data.data.ahlan_lounge.map(
          (item) => item._id
        );
        setspaSelected(spa);
        setSelectedItems(ahlan_lounge);
        setAhlanSelected(ahlan);
        setStartDate(response.data.data.from);
        setEndDate(response.data.data.to);
        console.log(spa, "ahlan");

        // setSelectedItems(response.data.data)
        // setStartDate(response.data.data.from)
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getAhlan());
    dispatch(getAhlanLounge());
    dispatch(getSpa());
  }, []);

  return (
    <div className="add-timerestriction">
      <AdminNavbar>
        <Box as="section">
          <Grid
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(3, 1fr)"
            className={styles.details}
            gap={4}
          >
            <GridItem
              colSpan={{ base: 4, xl: 4 }}
              className={styles.recentOrders}
            >
              <Box className={styles.cardHeader}>
                <Heading>Select Service And Duration</Heading>
              </Box>
              <TableContainer className={styles.tableContainer}>
                <Table variant="simple" size="lg">
                  {/* Map over ahlanLoungeState */}
                  {ahlanLoungeState &&
                    ahlanLoungeState.map((item) => (
                      <div key={item._id}>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedItems.includes(item._id)}
                            onChange={() => {
                              if (selectedItems.includes(item._id)) {
                                setSelectedItems(
                                  selectedItems.filter(
                                    (_id) => _id !== item._id
                                  )
                                );
                              } else {
                                setSelectedItems([...selectedItems, item._id]);
                              }
                            }}
                          />
                          {item.title} (Ahlan Lounge)
                        </label>
                      </div>
                    ))}

                  {/* Map over ahlans */}
                  {ahlans &&
                    ahlans.map((item) => (
                      <div key={item._id}>
                        <label>
                          <input
                            type="checkbox"
                            checked={ahlanSelected.includes(item._id)}
                            onChange={() => {
                              if (ahlanSelected.includes(item._id)) {
                                setAhlanSelected(
                                  ahlanSelected.filter(
                                    (_id) => _id !== item._id
                                  )
                                );
                              } else {
                                setAhlanSelected([...ahlanSelected, item._id]);
                              }
                            }}
                          />
                          {item.title} (Ahlan)
                        </label>
                      </div>
                    ))}

                  {/* Map over spas */}
                  {spas &&
                    spas.map((item) => (
                      <div key={item._id}>
                        <label>
                          <input
                            type="checkbox"
                            checked={spaSelected.includes(item._id)}
                            onChange={() => {
                              if (spaSelected.includes(item._id)) {
                                setspaSelected(
                                  spaSelected.filter((_id) => _id !== item._id)
                                );
                              } else {
                                setspaSelected([...spaSelected, item._id]);
                              }
                            }}
                          />
                          {item.serviceTitle} (Spa)
                        </label>
                      </div>
                    ))}
                </Table>
                <div>
                  <label>From:</label>
                  <DatePicker
                    selected={id ? new Date(startDate) : startDate}
                    onChange={(date) => setStartDate(date)}
                    showTimeInput
                    timeInputLabel="Time:"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
                <div>
                  <label>To:</label>
                  <DatePicker
                    selected={id ? new Date(endDate) : endDate}
                    onChange={(date) => setEndDate(date)}
                    showTimeInput
                    timeInputLabel="Time:"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
                {/* <button >Save</button> */}
                <button
                  onClick={handleSave}
                  className="writeSubmit"
                  type="submit"
                >
                  Publish
                </button>
              </TableContainer>
            </GridItem>
          </Grid>
        </Box>
      </AdminNavbar>
    </div>
  );
}

export default AddTimeRestrictionScreen;
{
  /* <Box as="section">
<Grid
  templateRows="repeat(1, 1fr)"
  templateColumns="repeat(3, 1fr)"
  gap={4}
>
  <GridItem colSpan={{ base: 4, xl: 4 }}>
    <Box>
      <Heading>Ahlan Details</Heading>
      <TableContainer>
      
      </TableContainer>
      
    </Box>
  </GridItem>
</Grid>
</Box> */
}
