import axios from "axios";
import { ADMIN_SIGNIN_FAIL, ADMIN_SIGNIN_REQUEST, ADMIN_SIGNIN_SUCCESS, ADMIN_SIGNOUT, GET_FORM_FAIL, GET_FORM_REQUEST, GET_FORM_SUCCESS } from "../constants/Admin";
import { Url } from "../constants/Blog";

export const adminSignIn =(email,pass)=>async(dispatch)=>{
    dispatch({type:ADMIN_SIGNIN_REQUEST,payload:{email,pass}});
    try{
      const {data}= await axios.post(`${Url}/api/v1/auth/login`,{email,pass})
      dispatch({type:ADMIN_SIGNIN_SUCCESS,payload:data});
      console.log(data.token,'tokn');
      localStorage.setItem('adminInfoDih', JSON.stringify(data.token));
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: ADMIN_SIGNIN_FAIL, payload: message })
    }
  }

  export const adminSignout = () => (dispatch) => {
    localStorage.removeItem('adminInfoDih');
    dispatch({ type: ADMIN_SIGNOUT });
    // document.location.href = '/signin';
  };


  export const getForms =()=>async(dispatch)=>{
    dispatch({type:GET_FORM_REQUEST});
    try{
      const {data}= await axios.get(`${Url}/api/v1/contact-form`)
      dispatch({type:GET_FORM_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: GET_FORM_FAIL, payload: message })
    }
  }