import React, { useEffect, useState } from 'react'
import {
  Box,
  Heading,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  GridItem,
  Grid,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import styles from "../styles/Dashboard.module.css";
import { Link, useNavigate } from "react-router-dom";
import { DELETE_SPA_RESET, FETCH_SPA_DETAILS_RESET, SPA_CREATE_RESET } from '../constants/Spa';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSpa, getSpa } from '../actions/SpaAction';
import Swal from 'sweetalert2';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import AdminNavbar from '../components/AdminNavbar';

function SpaScreen() {
  const createSpa=useSelector(state=>state.createSpa)
  const {success}=createSpa
  const getSpas=useSelector(state=>state.getSpas)
  const {loading,error,spas}=getSpas
  const deleteSpas=useSelector(state=>state.deleteSpa)
  const {success:successDelete,error:errorDelete}=deleteSpas
  const dispatch=useDispatch()
  useEffect(()=>{
    dispatch({type:FETCH_SPA_DETAILS_RESET})
    if(success){
      dispatch({type:SPA_CREATE_RESET})
     
    }
    if(successDelete){
      dispatch({type:DELETE_SPA_RESET})
      Swal.fire({
        title: 'Spa deleted Succesfully.',
        text: "Thanks",
        type: 'success',    
        icon: 'success',        
      }); 
    }
    dispatch(getSpa())
  },[success,successDelete])

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");

  const openDeleteModal = (id) => {
    setItemToDelete(id);
    setIsDeleteModalOpen(true);
  };



  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const navigate = useNavigate();
  return (
    <div>
      <AdminNavbar>
      <Box as="section">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <Box className={styles.cardHeader}>
              <Heading>Spa Details</Heading>
              <Link to={"/add-spa"}>
                <a className={styles.adminBtn}>Add Spa</a>
              </Link>
            </Box>
            <TableContainer className={styles.tableContainer}>
              <Table variant="simple" size="lg">
                <Thead>
                  <Tr>
                    <Th style={{width:'20%'}}>Image</Th>
                    <Th style={{width:'40%'}}>Service</Th>
                    <Th style={{width:'8%'}}>Action</Th>
                  </Tr>
                </Thead>
                {loading? <LoadingBox></LoadingBox>:
                error? <MessageBox>{error}</MessageBox>:
                spas.map((fq)=>(
                  <Tbody key={fq._id}>
                  <Tr>
                    <Td><img src={fq.image} alt="" /></Td>
                    <Td>{truncate(fq.serviceTitle,25)}</Td>
                    {/* <Td>{truncate(fq.answer,80)}</Td> */}
      
                    <Td>
                    <span className={styles.inProgress} style={{cursor:'pointer'}} onClick={() => {navigate(`/edit-spa/${fq._id}`);
  }}>Edit</span>
                      <span className={styles.inProgress} style={{cursor:'pointer'}} onClick={()=>openDeleteModal(fq._id)}>Delete</span>
                    </Td>
                    {/* <Td>
                      <span className={styles.inProgress}>Edit</span>
                    </Td> */}
                  </Tr>
                </Tbody>
                ))}
                
              </Table>
            </TableContainer>
          </GridItem>
        </Grid>
      </Box>
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Confirm Delete</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      Are you sure you want to delete this item?
    </ModalBody>
    <ModalFooter>
      <Button colorScheme="red" onClick={() => {
        setIsDeleteModalOpen(false); // Close the modal
        dispatch(deleteSpa(itemToDelete)); // Actually delete the item
      }}>
        Yes
      </Button>
      <Button variant="ghost" onClick={() => setIsDeleteModalOpen(false)}>No</Button>
    </ModalFooter>
  </ModalContent>
</Modal>
      </AdminNavbar>
    </div>
  )
}

export default SpaScreen