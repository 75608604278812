export const BLOG_CREATE_REQUEST = "BLOG_CREATE_REQUEST";
export const BLOG_CREATE_SUCCESS = "BLOG_CREATE_SUCCESS";
export const BLOG_CREATE_FAIL = "BLOG_CREATE_FAIL";
export const BLOG_CREATE_RESET = "BLOG_CREATE_RESET";

export const FETCH_BLOG_REQUEST = "FETCH_BLOG_REQUEST";
export const FETCH_BLOG_SUCCESS = "FETCH_BLOG_SUCCESS";
export const FETCH_BLOG_FAIL = "FETCH_BLOG_FAIL";

export const DELETE_BLOG_REQUEST = "DELETE_BLOG_REQUEST";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAIL = "DELETE_BLOG_FAIL";
export const DELETE_BLOG_RESET = "DELETE_BLOG_RESET";


// Constants for fetching a blog by ID
export const FETCH_BLOG_DETAILS_REQUEST = "FETCH_BLOG_DETAILS_REQUEST";
export const FETCH_BLOG_DETAILS_SUCCESS = "FETCH_BLOG_DETAILS_SUCCESS";
export const FETCH_BLOG_DETAILS_FAIL = "FETCH_BLOG_DETAILS_FAIL";
export const FETCH_BLOG_DETAILS_RESET = "FETCH_BLOG_DETAILS_RESET";
// Constants for editing a blog
export const EDIT_BLOG_REQUEST = "EDIT_BLOG_REQUEST";
export const EDIT_BLOG_SUCCESS = "EDIT_BLOG_SUCCESS";
export const EDIT_BLOG_FAIL = "EDIT_BLOG_FAIL";
export const EDIT_BLOG_RESET='EDIT_BLOG_RESET'

// export const Url='https://dih-backend.herokuapp.com'
// export const Url='https://dih-backend.up.railway.app'
export const Url = "https://dih-node-vercel-production-3413.up.railway.app";
// export const Url = "http://localhost:3003"
