export const SPA_CREATE_REQUEST = "SPA_CREATE_REQUEST";
export const SPA_CREATE_SUCCESS = "SPA_CREATE_SUCCESS";
export const SPA_CREATE_FAIL = "SPA_CREATE_FAIL";
export const SPA_CREATE_RESET = "SPA_CREATE_RESET";

export const FETCH_SPA_REQUEST = "FETCH_SPA_REQUEST";
export const FETCH_SPA_SUCCESS = "FETCH_SPA_SUCCESS";
export const FETCH_SPA_FAIL = "FETCH_SPA_FAIL";

export const DELETE_SPA_REQUEST = "DELETE_SPA_REQUEST";
export const DELETE_SPA_SUCCESS = "DELETE_SPA_SUCCESS";
export const DELETE_SPA_FAIL = "DELETE_SPA_FAIL";
export const DELETE_SPA_RESET = "DELETE_SPA_RESET";


// Constants for fetching a spa by ID
export const FETCH_SPA_DETAILS_REQUEST = "FETCH_SPA_DETAILS_REQUEST";
export const FETCH_SPA_DETAILS_SUCCESS = "FETCH_SPA_DETAILS_SUCCESS";
export const FETCH_SPA_DETAILS_FAIL = "FETCH_SPA_DETAILS_FAIL";
export const FETCH_SPA_DETAILS_RESET = "FETCH_SPA_DETAILS_RESET";


// Constants for editing a spa
export const EDIT_SPA_REQUEST = "EDIT_SPA_REQUEST";
export const EDIT_SPA_SUCCESS = "EDIT_SPA_SUCCESS";
export const EDIT_SPA_FAIL = "EDIT_SPA_FAIL";
export const EDIT_SPA_RESET = "EDIT_SPA_RESET";



// export const Url='https://dih-backend.herokuapp.com'
// export const Url='https://dih-backend.up.railway.app'
export const Url = "https://dih-node-vercel-production-3413.up.railway.app";
