import {
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore as createStore,
} from "redux";
import thunk from "redux-thunk";
import {
  createBannerReducer,
  deleteBannerReducer,
  getBannerReducer,
} from "./reducers/BannerReducer";
import {
  createFaqReducer,
  deleteFaqReducer,
  editFaqReducer,
  fetchFaqReducer,
  getFaqsReducer,
} from "./reducers/FaqReducer";
import {
  createGalleryReducer,
  deleteGalleryReducer,
  getGalleryReducer,
} from "./reducers/GalleryReducer";
import { adminSigninReducer, getFormsReducer } from "./reducers/LoginReducer";
import {
  createReviewReducer,
  deleteReviewReducer,
  editReviewReducer,
  fetchReviewReducer,
  getReviewsReducer,
} from "./reducers/ReviewReducer";
import {
  createSliderReducer,
  deleteSliderReducer,
  getSlidersReducer,
} from "./reducers/SliderReducer";
import {
  createBlogReducer,
  deleteBlogReducer,
  editBlogReducer,
  fetchBlogReducer,
  getBlogsReducer,
} from "./reducers/UploadReducers";
import {
  createAhlanReducer,
  deleteAhlanReducer,
  editAhlanReducer,
  fetchAhlanReducer,
  getAhlansReducer,
} from "./reducers/AhlanReducers";
import {
  createSpaReducer,
  deleteSpaReducer,
  editSpaReducer,
  fetchSpaReducer,
  getSpasReducer,
} from "./reducers/SpaReducers";
import {
  deleteAhlanLoungeReducer,
  getAhlanLoungeReducer,
  createAhlanLoungeReducer,
  fetchAhlanLoungeReducer,
  editAhlanLoungeReducer,
} from "./reducers/AhlanLoungeReducers";
import {
  deleteTierReducer,
  getTierReducer,
  createTierReducer,
} from "./reducers/TierReducers";

const initialState = {
  adminSignin: {
    adminInfo: localStorage.getItem("adminInfoDih")
      ? JSON.parse(localStorage.getItem("adminInfoDih"))
      : null,
  },
};

const reducer = combineReducers({
  createBlog: createBlogReducer,
  getBlogs: getBlogsReducer,
  deleteBlog: deleteBlogReducer,
  getAhlans: getAhlansReducer,
  deleteAhlan: deleteAhlanReducer,
  createAhlan: createAhlanReducer,
  getAhlanLoungeState: getAhlanLoungeReducer,
  deleteAhlanLoungeState: deleteAhlanLoungeReducer,
  createAhlanLoungeState: createAhlanLoungeReducer,
  getTierState: getTierReducer,
  deleteTierState: deleteTierReducer,
  createTierState: createTierReducer,
  createSpa: createSpaReducer,
  getSpas: getSpasReducer,
  deleteSpa: deleteSpaReducer,
  createBanner: createBannerReducer,
  getBanner: getBannerReducer,
  deleteBanner: deleteBannerReducer,
  createGallery: createGalleryReducer,
  getGallery: getGalleryReducer,
  deleteGallery: deleteGalleryReducer,
  createReview: createReviewReducer,
  getReview: getReviewsReducer,
  deleteReview: deleteReviewReducer,
  createFaq: createFaqReducer,
  getFaq: getFaqsReducer,
  deleteFaq: deleteFaqReducer,
  adminSignin: adminSigninReducer,
  getSlider: getSlidersReducer,
  deleteSlider: deleteSliderReducer,
  createSlider: createSliderReducer,
  getForms: getFormsReducer,
  editBlog:editBlogReducer,
  fetchBlog:fetchBlogReducer,
  editSpa:editSpaReducer,
  fetchSpa:fetchSpaReducer,
  editAhlan:editAhlanReducer,
  fetchAhlan:fetchAhlanReducer,
  editReview:editReviewReducer,
  fetchReview:fetchReviewReducer,
  editFaq:editFaqReducer,
  fetchFaq:fetchFaqReducer,
  fetchAhlanLounge:fetchAhlanLoungeReducer,
  editAhlanLounge:editAhlanLoungeReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
