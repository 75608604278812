import React, { useEffect, useState } from "react";
import { Box, GridItem, Grid } from "@chakra-ui/react";
import styles from "../styles/Dashboard.module.css";
import {
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
import { Textarea } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addFaq } from "../actions/FaqAction";
import Swal from "sweetalert2";
import AdminNavbar from "../components/AdminNavbar";
import LoadingBox from "../components/LoadingBox";
import axios from "axios";

function AddTier() {
    const [tier, setTier] = useState('')
    const [credit, setCredit] = useState('')
    const navigate=useNavigate()

const submitHandler = async (e) => {
  e.preventDefault();

  try {
    const response = await axios.post(
      "https://dih-node-vercel-production-3413.up.railway.app/api/v1/tier",
      {
        tier_name: tier,
        credit: credit,
      }
    );

    if (response.status === 200) {
      Swal.fire({
        title: "New Tier created Successfully.",
        text: "Thanks",
        icon: "success",
      });
      navigate("/tier");
    }
  } catch (error) {
    // Handle error
    console.error("Error submitting tier:", error);
  }
};

  return (
    <div>
      <AdminNavbar>
      <Box as="section"  className="faq">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <form onSubmit={submitHandler}>
            <FormControl isRequired className="faq-form">
              <FormLabel>Tier Name</FormLabel>
              <Input placeholder="Name" onChange={(e)=>setTier(e.target.value)} required/>
              <FormLabel>Credit</FormLabel>
              <Input  placeholder="Credit" onChange={(e)=>setCredit(e.target.value)} required/>
              <button type="submit">submit</button>
            </FormControl>
            </form>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  );
}

export default AddTier;
