import {
  AHLANLOUNGE_CREATE_FAIL,
  AHLANLOUNGE_CREATE_REQUEST,
  AHLANLOUNGE_CREATE_RESET,
  AHLANLOUNGE_CREATE_SUCCESS,
  DELETE_AHLANLOUNGE_FAIL,
  DELETE_AHLANLOUNGE_REQUEST,
  DELETE_AHLANLOUNGE_RESET,
  DELETE_AHLANLOUNGE_SUCCESS,
  EDIT_AHLANLOUNGE_FAIL,
  EDIT_AHLANLOUNGE_REQUEST,
  EDIT_AHLANLOUNGE_RESET,
  EDIT_AHLANLOUNGE_SUCCESS,
  FETCH_AHLANLOUNGE_DETAILS_FAIL,
  FETCH_AHLANLOUNGE_DETAILS_REQUEST,
  FETCH_AHLANLOUNGE_DETAILS_RESET,
  FETCH_AHLANLOUNGE_DETAILS_SUCCESS,
  FETCH_AHLANLOUNGE_FAIL,
  FETCH_AHLANLOUNGE_REQUEST,
  FETCH_AHLANLOUNGE_SUCCESS,
} from "../constants/AhlanLounge";

export const createAhlanLoungeReducer = (state = {}, action) => {
  switch (action.type) {
    case AHLANLOUNGE_CREATE_REQUEST:
      return { loading: true };
    case AHLANLOUNGE_CREATE_SUCCESS:
      return { loading: false, success: true, response: action.payload };
    case AHLANLOUNGE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case AHLANLOUNGE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const getAhlanLoungeReducer = (
  state = { loading: true, ahlanLoungeState: [] },
  action
) => {
  switch (action.type) {
    case FETCH_AHLANLOUNGE_REQUEST:
      return { loading: true };
    case FETCH_AHLANLOUNGE_SUCCESS:
      return { loading: false, ahlanLoungeState: action.payload.data };
    case FETCH_AHLANLOUNGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteAhlanLoungeReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_AHLANLOUNGE_REQUEST:
      return { loading: true };
    case DELETE_AHLANLOUNGE_SUCCESS:
      return { loading: false, success: true, response: action.payload };
    case DELETE_AHLANLOUNGE_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_AHLANLOUNGE_RESET:
      return {};
    default:
      return state;
  }
};


export const editAhlanLoungeReducer = (
  state = { loading: true, success: false },
  action
) => {
  switch (action.type) {
    case EDIT_AHLANLOUNGE_REQUEST:
      return {loading: true };
    case EDIT_AHLANLOUNGE_SUCCESS:
      return { loading: false, success: true };
    case EDIT_AHLANLOUNGE_FAIL:
      return { loading: false, error: action.payload };
      case EDIT_AHLANLOUNGE_RESET:
        return {}

    default:
      return state;
  }
};


export const fetchAhlanLoungeReducer = (
  state = { loading: true, ahlans: [] },
  action
) => {
  switch (action.type) {
    case FETCH_AHLANLOUNGE_DETAILS_REQUEST:
      return { loading: true };
    case FETCH_AHLANLOUNGE_DETAILS_SUCCESS:
      return { loading: false, ahlans: action.payload ,data:true};
    case FETCH_AHLANLOUNGE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case FETCH_AHLANLOUNGE_DETAILS_RESET:
      return {}
    default:
      return state;
  }
};