import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  GridItem,
  Grid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import styles from "../styles/Dashboard.module.css";
import AdminNavbar from "../components/AdminNavbar";
import axios from "axios";
import Swal from "sweetalert2";
import { Url } from "../constants/Blog";
import MessageBox from "../components/MessageBox";

function DisabledUserScreen() {
  const [userData, setUserData] = useState();
  const [selectedId, setSelectedId] = useState();
  const [amount, setAmount] = useState();

  const { isOpen: isOpen, onOpen: onOpen, onClose: onClose } = useDisclosure();

  const fetchData = async () => {
    try {
      const response = await axios.get(`${Url}/api/v1/user/disabled-users`);
      console.log("API response for Userdata:", response.data.data);
      setUserData(response.data.data); // Update the state with fetched data
    } catch (error) {
      console.error("Error submitting userData:", error);
    }
  };

  useEffect(() => {
    fetchData(); // Call the fetchData function
  }, []);

  //   const updateCredit =async (id) => {
  //     try {
  //       const response =await axios.put(`${Url}/api/v1/user/add-agency/${id}`)
  //       const data = await response
  //       console.log(data.data.success)
  //       if(data.data.success === 'true'){
  //         Swal.fire({
  //             title: 'User  Updated',
  //             text: "Thanks",
  //             type: 'success',
  //             icon: 'success',
  //           });
  //           fetchData()
  //       }

  //         // onClose()

  //     } catch (error) {
  //         console.error('Error while updating usercredit:', error);

  //           onClose()
  //           Swal.fire({
  //             title: 'User Credit Not Updated',
  //             text: "Sorry",
  //             type: 'error',
  //             icon: 'error',
  //           });
  //       }
  //   }

  const updateCredit = async (id) => {
    try {
      const response = await axios.put(
        `${Url}/api/v1/user/change-status/${id}`
      );
      if (response.data.success === true) {
        handleSuccess();
      } else {
        handleError();
      }
    } catch (error) {
      console.error("Error while updating user credit:", error);
      handleError();
    }
  };

  const handleSuccess = () => {
    Swal.fire({
      title: "User Updated",
      text: "Thanks",
      type: "success",
      icon: "success",
    });
    fetchData();
  };

  const handleError = () => {
    Swal.fire({
      title: "User  Not Updated",
      text: "Sorry",
      type: "error",
      icon: "error",
    });
  };

  const actionHandler = (id) => {
    console.log(id);
    Swal.fire({
      title: "Do you want to make this user as Active?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      //   denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        updateCredit(id);
        // Swal.fire("Saved!", "", "success");
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  return (
    <div>
      <AdminNavbar>
        <Box as="section">
          <Grid
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(3, 1fr)"
            className={styles.details}
            gap={4}
          >
            <GridItem
              colSpan={{ base: 4, xl: 4 }}
              className={styles.recentOrders}
            >
              <Box className={styles.cardHeader}>
                <Heading>User List</Heading>
                {/* <Link to={"/add-faq"}>
                <a className={styles.adminBtn}>Add Faq</a>
              </Link> */}
              </Box>
              <TableContainer className={styles.tableContainer}>
                <Table variant="simple" size="lg">
                  <Thead>
                    <Tr>
                      <Th style={{ width: "20%" }}>Name</Th>
                      <Th style={{ width: "20%" }}>Email</Th>
                      <Th style={{ width: "20%" }}>Mobile</Th>
                      {/* <Th style={{width:'20%'}}>Tier Name</Th>
                    <Th style={{width:'20%'}}>Credit</Th> */}
                      <Th style={{ width: "20%" }}>Action</Th>
                    </Tr>
                  </Thead>
                  {userData?.length === 0 && <MessageBox>No Users</MessageBox>}
                  {userData &&
                    userData.map((fq) => (
                      <Tbody key={fq._id}>
                        <Tr>
                          <Td>{fq.firstName + " " + fq.lastName}</Td>
                          <Td>{fq.email}</Td>
                          <Td>{fq.phoneNumber}</Td>
                          {/* <Td>{fq.tier?.tier_name}</Td>
                    <Td>{fq.credit}</Td> */}
                          {/* <Td><Button onClick={()=>{onOpen();setSelectedId(fq._id)}}>Make as Agency</Button></Td> */}
                          <Td>
                            <Button onClick={() => actionHandler(fq._id)}>
                              Active Account
                            </Button>
                          </Td>
                        </Tr>
                      </Tbody>
                    ))}
                </Table>
              </TableContainer>
            </GridItem>
          </Grid>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Edit User</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <label>Please enter the credit amount</label>
                <br />
                <input
                  className="normalInput"
                  placeholder="amount"
                  onChange={(e) => setAmount(e.target.value)}
                />
              </ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="teal"
                  mr={3}
                  onClick={() => {
                    updateCredit();
                  }}
                >
                  Submit
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      </AdminNavbar>
    </div>
  );
}

export default DisabledUserScreen;
