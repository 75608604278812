import axios from "axios";
import { Url } from "../constants/Blog";
import {
  REVIEW_CREATE_FAIL,
  REVIEW_CREATE_REQUEST,
  REVIEW_CREATE_SUCCESS,
  DELETE_REVIEW_FAIL,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  FETCH_REVIEW_FAIL,
  FETCH_REVIEW_REQUEST,
  FETCH_REVIEW_SUCCESS,
  FETCH_REVIEW_DETAILS_REQUEST,
  FETCH_REVIEW_DETAILS_SUCCESS,
  FETCH_REVIEW_DETAILS_FAIL,
  EDIT_REVIEW_REQUEST,
  EDIT_REVIEW_SUCCESS,
  EDIT_REVIEW_FAIL,
} from "../constants/Review";

export const addReview =
  (title, name, place, review, page) => async (dispatch) => {
    dispatch({ type: REVIEW_CREATE_REQUEST });
    try {
      const { data } = await axios.post(
        `${Url}/api/v1/review`,
        { title, name, place, data: review, page },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      dispatch({ type: REVIEW_CREATE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: REVIEW_CREATE_FAIL, payload: message });
    }
  };

export const getReview = () => async (dispatch) => {
  dispatch({ type: FETCH_REVIEW_REQUEST });
  try {
    const { data } = await axios.get(`${Url}/api/v1/review`);
    dispatch({ type: FETCH_REVIEW_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: FETCH_REVIEW_FAIL, payload: message });
  }
};

export const deleteReview = (id) => async (dispatch) => {
  dispatch({ type: DELETE_REVIEW_REQUEST });
  try {
    const { data } = await axios.delete(`${Url}/api/v1/review/${id}`);
    dispatch({ type: DELETE_REVIEW_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: DELETE_REVIEW_FAIL, payload: message });
  }
};


export const getReviewById = (id) => async (dispatch) => {
  dispatch({ type: FETCH_REVIEW_DETAILS_REQUEST });
  try {
    const { data } = await axios.get(`${Url}/api/v1/review/${id}`);
    dispatch({ type: FETCH_REVIEW_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: FETCH_REVIEW_DETAILS_FAIL, payload: message });
  }
};

export const editReviewAction = ( updatedData) => async (dispatch) => {
  dispatch({ type: EDIT_REVIEW_REQUEST });
  try {
    const { data } = await axios.put(
      `${Url}/api/v1/review`,
      updatedData,
     
    );
    dispatch({ type: EDIT_REVIEW_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: EDIT_REVIEW_FAIL, payload: message });
  }
};
