import axios from "axios";
import { Url } from "../constants/Blog";
import { SLIDER_CREATE_FAIL, SLIDER_CREATE_REQUEST, SLIDER_CREATE_SUCCESS, DELETE_SLIDER_FAIL, DELETE_SLIDER_REQUEST, DELETE_SLIDER_SUCCESS, FETCH_SLIDER_FAIL, FETCH_SLIDER_REQUEST, FETCH_SLIDER_SUCCESS} from "../constants/Slider";

export const addSlider =(page,image)=>async(dispatch)=>{
    dispatch({type:SLIDER_CREATE_REQUEST});
    try{
      const {data}= await axios.post(`${Url}/api/v1/slider`,{page,image},{
        headers:{'Content-Type': 'application/json'}  
      })
      dispatch({type:SLIDER_CREATE_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: SLIDER_CREATE_FAIL, payload: message })
    }
  }

  export const getSlider =()=>async(dispatch)=>{
    dispatch({type:FETCH_SLIDER_REQUEST});
    try{
      const {data}= await axios.get(`${Url}/api/v1/slider`)
      dispatch({type:FETCH_SLIDER_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: FETCH_SLIDER_FAIL, payload: message })
    }
  }

  export const deleteSlider =(id)=>async(dispatch)=>{
    dispatch({type:DELETE_SLIDER_REQUEST});
    try{
      const {data}= await axios.delete(`${Url}/api/v1/slider/${id}`)
      dispatch({type:DELETE_SLIDER_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: DELETE_SLIDER_FAIL, payload: message })
    }
  }