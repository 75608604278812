import { Box, Heading } from "@chakra-ui/react";
import React from "react";
import styles from "../styles/Admin.module.css";

const Breadcrumb = ({ BC }) => {
  return (
    <Box className={styles.breadcrumbSec}>
      <Box className={`${styles.breadcrumb} prdModalbc`}>
        <Heading as={"h4"}>{BC}</Heading>
      </Box>
    </Box>
  );
};

export default Breadcrumb;
