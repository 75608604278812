import { Box, Grid, GridItem, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteSlider, getSlider } from "../actions/SliderAction";
import AdminNavbar from "../components/AdminNavbar";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { DELETE_SLIDER_RESET, SLIDER_CREATE_RESET } from "../constants/Slider";
import styles from "../styles/Dashboard.module.css";

function HomeSliderScreen() {
  const createSlider=useSelector(state=>state.createSlider)
  const {success}=createSlider
  const getSliders=useSelector(state=>state.getSlider)
  const {loading,error,sliders}=getSliders
  const deleteSliders=useSelector(state=>state.deleteSlider)
  const {success:successDelete,error:errorDelete}=deleteSliders
  const dispatch=useDispatch()

  useEffect(()=>{
    if(success){
      dispatch({type:SLIDER_CREATE_RESET})
     
    }
    if(successDelete){
      dispatch({type:DELETE_SLIDER_RESET})
      Swal.fire({
        title: 'Image deleted Succesfully.',
        text: "Thanks",
        type: 'success',    
        icon: 'success',        
      }); 
    }
    dispatch(getSlider())
  },[success,successDelete])


  const deleteHandler=(id)=>{
    dispatch(deleteSlider(id))
  }
  return (
    <div>
      <AdminNavbar>
      <Box as="section">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <Box className={styles.cardHeader}>
              <Heading>Home Slider</Heading>
              <Link to={"/add-homeslider"}>
                <a className={styles.adminBtn}>Add Images</a>
              </Link>
            </Box>
            <div className="home-slider">
              {loading ? <LoadingBox></LoadingBox>:
              error? <MessageBox>{error}</MessageBox>:
              sliders[0].images.length> 0 ? sliders[0].images.map((sld)=>(
              <div key={sld._id}>
              <img
                src={sld.fileUrl}
                alt=""
              />
              <button onClick={()=>deleteHandler(sld._id)}>delete</button>
            </div>
              )):
            <MessageBox>No images</MessageBox>
            }
            
            
         
            </div>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  );
}

export default HomeSliderScreen;
