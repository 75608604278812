import React, { useEffect, useState } from 'react'
import {
  Box,
  Heading,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  GridItem,
  Grid,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import styles from "../styles/Dashboard.module.css";
import { Link, useNavigate } from "react-router-dom";
import { DELETE_AHLANLOUNGE_RESET, AHLANLOUNGE_CREATE_RESET, FETCH_AHLANLOUNGE_DETAILS_RESET } from '../constants/AhlanLounge';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAhlanLounge, getAhlanLounge } from '../actions/AhlanLoungeAction';
import Swal from 'sweetalert2';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import AdminNavbar from '../components/AdminNavbar';

function AhlanLoungeScreen() {
  const createAhlanLoungeState=useSelector(state=>state.createAhlanLoungeState)
  const {success}=createAhlanLoungeState
  const getAhlanLoungeState=useSelector(state=>state.getAhlanLoungeState)
  const {loading,error,ahlanLoungeState}=getAhlanLoungeState
  const deleteAhlanLoungeState=useSelector(state=>state.deleteAhlanLoungeState)
  const {success:successDelete,error:errorDelete}=deleteAhlanLoungeState
  const dispatch=useDispatch()
  const navigate = useNavigate()
  
  useEffect(()=>{
    dispatch({type:FETCH_AHLANLOUNGE_DETAILS_RESET})
    if(success){
      dispatch({type:AHLANLOUNGE_CREATE_RESET})
     
    }
    if(successDelete){
      dispatch({type:DELETE_AHLANLOUNGE_RESET})
      Swal.fire({
        title: 'Ahlan Lounge deleted Succesfully.',
        text: "Thanks",
        type: 'success',    
        icon: 'success',        
      }); 
    }
    dispatch(getAhlanLounge())
  },[success,successDelete])

  console.log(getAhlanLoungeState);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
const [itemToDelete, setItemToDelete] = useState("");

const openDeleteModal = (id) => {
  setItemToDelete(id);
  setIsDeleteModalOpen(true);
};

const deleteHandler = (id) => {
  deleteAhlanLounge(id);
};


  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  return (
    <div>
      <AdminNavbar>
      <Box as="section">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <Box className={styles.cardHeader}>
              <Heading>Ahlan Lounge Details</Heading>
              <Link to={"/add-ahlan-lounge"}>
                <a className={styles.adminBtn}>Add Ahlan Lounge</a>
              </Link>
            </Box>
            <TableContainer className={styles.tableContainer}>
              <Table variant="simple" size="lg">
                <Thead>
                  <Tr>
                    <Th style={{width:'20%'}}>Image</Th>
                    <Th style={{width:'40%'}}>Title</Th>
                    <Th style={{width:'40%'}}>Price</Th>
                    <Th style={{width:'40%'}}>Service</Th>
                    <Th style={{width:'8%'}}>Action</Th>
                  </Tr>
                </Thead>
                {loading? <LoadingBox></LoadingBox>:
                error? <MessageBox>{error}</MessageBox>:
                ahlanLoungeState.map((fq)=>(
                  <Tbody key={fq._id}>
                  <Tr>
                    <Td><img src={fq.image} alt="" /></Td>
                    <Td>{truncate(fq.title,25)}</Td>
                    <Td>Adult : {fq.adult} <br/>Extra : {fq.extra} <br/> Group : {fq.group} <br/> Children : {fq.children}</Td>
                    <Td>{fq.service}</Td>
                    <Td>
                    <span className={styles.inProgress} style={{cursor:'pointer'}} onClick={() => {navigate(`/edit-ahlan-lounge/${fq._id}`);}}>Edit</span>
                      <span className={styles.inProgress} style={{cursor:'pointer'}} onClick={()=>openDeleteModal(fq._id)}>Delete</span>
                    </Td>
                  </Tr>
                </Tbody>
                ))}
                
              </Table>
            </TableContainer>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Confirm Delete</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      Are you sure you want to delete this item?
    </ModalBody>
    <ModalFooter>
      <Button colorScheme="red" onClick={() => {
        setIsDeleteModalOpen(false); // Close the modal
        dispatch(deleteAhlanLounge(itemToDelete)); // Actually delete the item
      }}>
        Yes
      </Button>
      <Button variant="ghost" onClick={() => setIsDeleteModalOpen(false)}>No</Button>
    </ModalFooter>
  </ModalContent>
</Modal>
    </div>
  )
}

export default AhlanLoungeScreen;