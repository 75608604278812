import axios from "axios";
import { Url } from "../constants/Blog";
import { FAQ_CREATE_FAIL, FAQ_CREATE_REQUEST, FAQ_CREATE_SUCCESS, DELETE_FAQ_FAIL, DELETE_FAQ_REQUEST, DELETE_FAQ_SUCCESS, FETCH_FAQ_FAIL, FETCH_FAQ_REQUEST, FETCH_FAQ_SUCCESS, FETCH_FAQ_DETAILS_REQUEST, FETCH_FAQ_DETAILS_SUCCESS, FETCH_FAQ_DETAILS_FAIL, EDIT_FAQ_REQUEST, EDIT_FAQ_SUCCESS, EDIT_FAQ_FAIL } from "../constants/Faq";

export const addFaq =(question,answer)=>async(dispatch)=>{
    dispatch({type:FAQ_CREATE_REQUEST});
    try{
      const {data}= await axios.post(`${Url}/api/v1/faq`,{question,answer},{
        headers:{'Content-Type': 'application/json'}  
      })
      dispatch({type:FAQ_CREATE_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: FAQ_CREATE_FAIL, payload: message })
    }
  }

  export const getFaq =()=>async(dispatch)=>{
    dispatch({type:FETCH_FAQ_REQUEST});
    try{
      const {data}= await axios.get(`${Url}/api/v1/faq`)
      dispatch({type:FETCH_FAQ_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: FETCH_FAQ_FAIL, payload: message })
    }
  }

  export const deleteFaq =(id)=>async(dispatch)=>{
    dispatch({type:DELETE_FAQ_REQUEST});
    try{
      const {data}= await axios.delete(`${Url}/api/v1/faq/${id}`)
      dispatch({type:DELETE_FAQ_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: DELETE_FAQ_FAIL, payload: message })
    }
  }


  export const getFaqById = (id) => async (dispatch) => {
    dispatch({ type: FETCH_FAQ_DETAILS_REQUEST });
    try {
      const { data } = await axios.get(`${Url}/api/v1/faq/${id}`);
      dispatch({ type: FETCH_FAQ_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: FETCH_FAQ_DETAILS_FAIL, payload: message });
    }
  };
  
  export const editFaqAction = ( updatedData) => async (dispatch) => {
    dispatch({ type: EDIT_FAQ_REQUEST });
    try {
      const { data } = await axios.put(
        `${Url}/api/v1/faq`,
        updatedData,
      );
      dispatch({ type: EDIT_FAQ_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: EDIT_FAQ_FAIL, payload: message });
    }
  };
  