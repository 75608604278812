import axios from "axios";
import { BANNER_CREATE_FAIL, BANNER_CREATE_REQUEST, BANNER_CREATE_SUCCESS, DELETE_BANNER_FAIL, DELETE_BANNER_REQUEST, DELETE_BANNER_SUCCESS, FETCH_BANNER_FAIL, FETCH_BANNER_REQUEST, FETCH_BANNER_SUCCESS } from "../constants/Banner";
import { Url } from "../constants/Blog";

export const addBanner =(page,largeImage,mobileImage)=>async(dispatch)=>{
    dispatch({type:BANNER_CREATE_REQUEST});
    try{
      const {data}= await axios.post(`${Url}/api/v1/banner`,{page,largeImage,mobileImage},{
        headers:{'Content-Type': 'application/json'}  
      })
      dispatch({type:BANNER_CREATE_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: BANNER_CREATE_FAIL, payload: message })
    }
  }

  export const getBanner =()=>async(dispatch)=>{
    dispatch({type:FETCH_BANNER_REQUEST});
    try{
      const {data}= await axios.get(`${Url}/api/v1/banner`)
      dispatch({type:FETCH_BANNER_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: FETCH_BANNER_FAIL, payload: message })
    }
  }

  export const deleteBanner =(id)=>async(dispatch)=>{
    dispatch({type:DELETE_BANNER_REQUEST});
    try{
      const {data}= await axios.delete(`${Url}/api/v1/blog/${id}`)
      dispatch({type:DELETE_BANNER_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: DELETE_BANNER_FAIL, payload: message })
    }
  }