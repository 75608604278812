import React, { useEffect, useState } from 'react'
import {
  Box,
  Heading,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  GridItem,
  Grid,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
  Button,
  Select,
} from "@chakra-ui/react";
import styles from "../styles/Dashboard.module.css";
import { Link,useNavigate } from "react-router-dom";
import { DELETE_TIER_RESET,  TIER_CREATE_RESET } from '../constants/Tier';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTier, getTier } from '../actions/TierAction';
import Swal from 'sweetalert2';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import AdminNavbar from '../components/AdminNavbar';
import axios from 'axios';

function TierScreen() {
  const createTierState=useSelector(state=>state.createTierState)
  const {success}=createTierState
  const getTierState=useSelector(state=>state.getTierState)
  const {loading,error,tierState}=getTierState
  const deleteTierState=useSelector(state=>state.deleteTierState)
  const {success:successDelete,error:errorDelete}=deleteTierState
  const dispatch=useDispatch()
  const navigate = useNavigate();
  const [selectedEmails, setSelectedEmails] = useState(new Set()); // Using a Set to store unique values
  const [userData, setUserData] = useState([]);
  const [tierId, setTierId] = useState()
  const [deleteId, setDeleteId] = useState()

    const handleEmailSelect = (selectedValues) => {
        const updatedEmails = new Set([...selectedEmails, ...selectedValues]); // Merge new and existing values into a Set
        setSelectedEmails(updatedEmails);
    };

    const handleDeleteEmail = (emailToDelete) => {
        const updatedEmails = new Set(Array.from(selectedEmails).filter(mail => mail !== emailToDelete));
        setSelectedEmails(updatedEmails);
    };
  
  const [loading1, setLoading1] = useState(false);

    const {
    isOpen: isOpen,
    onOpen: onOpen,
    onClose: onClose,
  } = useDisclosure();

    const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

    const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  

  useEffect(()=>{
    if(success){
      dispatch({type:TIER_CREATE_RESET})
     
    }
    if(successDelete){
      dispatch({type:DELETE_TIER_RESET})
      Swal.fire({
        title: 'Tier deleted Succesfully.',
        text: "Thanks",
        type: 'success',    
        icon: 'success',        
      }); 
    }
    dispatch(getTier())
  },[success,successDelete])

  useEffect(() => {
        // Fetch data when the component mounts
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
        const response = await axios.get('https://dih-node-vercel-production-3413.up.railway.app/api/v1/agency');
        // Assuming the response data contains an array of user data
        setUserData(response.data.data);
        console.log(response.data.data,"ll");
        } catch (error) {
        console.error('Error fetching data:', error);
        }
    };

const sendEmailData = async () => {
  const selectedEmailValuesArray = Array.from(selectedEmails);

  const selectedIds = selectedEmailValuesArray.map(selectedValue => {
    const user = userData.find(user => user.email === selectedValue);
    return user ? user._id : null;
  });

  let userAdded = false

  for (const userId of selectedIds) {
    if (userId) {
      const requestData = {
        user_id: userId,
        tier_id: tierId
      };

      try {
        const response = await axios.put('https://dih-node-vercel-production-3413.up.railway.app/api/v1/agency/update-tier', requestData);
        console.log('API response:', response.data);
        userAdded = response.data.success
      } catch (error) {
        console.error('Error sending data:', error);
      }
    }
  }

  if(userAdded) {
        onClose()
        Swal.fire({
            title: 'User Added Succesfully.',
            text: "Thanks",
            type: 'success',    
            icon: 'success',        
        }); 
    }
};
  


  const deleteHandler=(id)=>{
    dispatch(deleteTier(id))
    onClose1()
  }

  console.log(tierState);

  return (
    <div>
      <AdminNavbar>
      <Box as="section">
        <Link to={"/add-tier"} style={{  width: "150px",textAlign: "center",marginLeft: "auto",marginRight: "2%",marginTop: "25px"}}>
                <a className={styles.adminBtn} style={{textAlign:"center", padding:"10px"}}>Add Tier</a>
        </Link>
        
        {loading? <LoadingBox></LoadingBox>:
                error? <MessageBox>{error}</MessageBox>:
                tierState.map((tr)=>(
<Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <Box className={styles.cardHeader}>
              <Heading>{tr.tier_name} Details</Heading>
              <div style={{display:"flex"}}>
              <div>
                <Text style={{padding:"7px 20px"}}>Given Credit : {tr.credit}</Text>
              </div>
              <Button
                onClick={() => {
                onOpen2()
                setTierId(tr._id)
                }}
                style={{marginRight:"10px"}}
              >
                  List User
              </Button>
              <Button
                onClick={(e) => {
                onOpen(e)
                setTierId(tr._id)
                }}
                style={{marginRight:"10px"}}
            >
                Add Users
            </Button>
            <Button onClick={() => { onOpen1(); setDeleteId(tr._id); }}>Delete</Button>
            </div>
            </Box>
            <TableContainer className={styles.tableContainer}>
              <Table variant="simple" size="lg">
                <Thead>
                  <Tr>
                    <Th style={{width:'20%'}}>Image</Th>
                    <Th style={{width:'40%'}}>Service</Th>
                    <Th style={{width:'8%'}}>Action</Th>
                  </Tr>
                </Thead>
                  <Tbody >
                  <Tr>
                    <Td><img src='/assets/images/meet.jpg' style={{maxHeight:"150px",width:"100%",objectFit:"cover"}}  /></Td>
                    <Td>Manage Ahlan Meet and Greet Rate</Td>
      
                    <Td>
                      <span className={styles.inProgress} style={{cursor:'pointer',padding:"10px 30px"}} onClick={()=>navigate(`/tier-update/?tierId=${tr._id}&serviceName=meet`)}>Edit</span>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td><img src='/assets/images/lounge.jpg' style={{maxHeight:"150px",width:"100%",objectFit:"cover"}}  /></Td>
                    <Td>Manage Ahlan Lounge Rate</Td>
      
                    <Td>
                      <span className={styles.inProgress} style={{cursor:'pointer',padding:"10px 30px"}} onClick={()=>navigate(`/tier-update/?tierId=${tr._id}&serviceName=lounge`)}>Edit</span>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td><img src='/assets/images/spa.jpg' style={{maxHeight:"150px",width:"100%",objectFit:"cover"}} /></Td>
                    <Td>Manage Spa Rate</Td>
      
                    <Td>
                      <span className={styles.inProgress} style={{cursor:'pointer',padding:"10px 30px"}} onClick={()=>navigate(`/tier-update/?tierId=${tr._id}&serviceName=spa`)}>Edit</span>
                    </Td>
                  </Tr>
                </Tbody>
                
              </Table>
            </TableContainer>
          </GridItem>
        </Grid>
        ))
}
        
    <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Travel Agency</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>Select Email</Text>
                    <Select
                        isMulti
                        value={selectedEmails}
                        onChange={(e) => {
                            const selectedOptions = e.target.selectedOptions;
                            const selectedValues = Array.from(selectedOptions).map(option => option.value);
                            handleEmailSelect(selectedValues);
                        }}
                    >
                        <option>Choose the Emails</option>
                        {userData.map(user => (
                            <option value={user.email}>{user.email}</option>
                        ))}
                        {/* Add more email options here */}
                    </Select>
                    <Text mt={5}>Selected Emails:</Text>
                        {Array.from(selectedEmails).map((mail, index) => (
                            <Text key={index} style={{display:"flex",justifyContent:"space-between"}}>{mail} <button onClick={() => handleDeleteEmail(mail)} style={{color:"red"}} >Delete</button></Text>
                        ))}
                </ModalBody>

                <ModalFooter>
                    <Button
                        colorScheme="teal"
                        isLoading={loading1}
                        mr={3}
                        onClick={sendEmailData}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
        
    <Modal isOpen={isOpen1} onClose={onClose1}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete Tier</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>Do you want to delete this tier?</Text>
                </ModalBody>

                <ModalFooter>
                    <Button
                        
                        colorScheme="red"
                        mr={3}
                        onClick={()=>{deleteHandler(deleteId)}}
                    >
                        Delete
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    <Modal isOpen={isOpen2} onClose={onClose2}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>List of Tier Users</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {userData.map((usr) => (
                      usr.tier?._id === tierId && <div key={usr.email}>{usr.email}</div>
                    ))}
                </ModalBody>

                <ModalFooter>
                    <Button
                        
                        colorScheme="red"
                        mr={3}
                        onClick={()=>{onClose2()}}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
      </Box>
      </AdminNavbar>
    </div>
  )
}

export default TierScreen