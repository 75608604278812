import { FAQ_CREATE_FAIL, FAQ_CREATE_REQUEST, FAQ_CREATE_RESET, FAQ_CREATE_SUCCESS, DELETE_FAQ_FAIL, DELETE_FAQ_REQUEST, DELETE_FAQ_RESET, DELETE_FAQ_SUCCESS, FETCH_FAQ_FAIL, FETCH_FAQ_REQUEST, FETCH_FAQ_SUCCESS, FETCH_FAQ_DETAILS_REQUEST, FETCH_FAQ_DETAILS_SUCCESS, FETCH_FAQ_DETAILS_FAIL, EDIT_FAQ_REQUEST, EDIT_FAQ_SUCCESS, EDIT_FAQ_FAIL, EDIT_FAQ_RESET, FETCH_FAQ_DETAILS_RESET } from "../constants/Faq";

export const createFaqReducer= (state= {}, action)=>{
    switch(action.type){
      case FAQ_CREATE_REQUEST:
        return {loading:true};
      case FAQ_CREATE_SUCCESS:
        return {loading:false,success:true, response:action.payload};
      case FAQ_CREATE_FAIL:
        return {loading:false,error:action.payload}
      case FAQ_CREATE_RESET:
        return {};
      default:
        return state;        
    }
  }


  export const getFaqsReducer =(state={loading:true,faqs:[]},action)=>{
    switch(action.type){
      case FETCH_FAQ_REQUEST:
        return {loading:true}
      case FETCH_FAQ_SUCCESS:
        return {loading:false,faqs:action.payload.data}
      case FETCH_FAQ_FAIL:
        return {loading:false, error:action.payload}
      default:
        return state;      
    }
  }

  export const deleteFaqReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_FAQ_REQUEST:
        return { loading: true };
      case DELETE_FAQ_SUCCESS:
        return { loading: false, success: true ,response:action.payload};
      case DELETE_FAQ_FAIL:
        return { loading: false, error: action.payload };
      case DELETE_FAQ_RESET:
        return {};
      default:
        return state;
    }
  };


  export const fetchFaqReducer = (
    state = { loading: true, faqs: [] },
    action
  ) => {
    switch (action.type) {
      case FETCH_FAQ_DETAILS_REQUEST:
        return {  loading: true };
      case FETCH_FAQ_DETAILS_SUCCESS:
        return { loading: false, faqs: action.payload ,data:true};
      case FETCH_FAQ_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      case FETCH_FAQ_DETAILS_RESET:
        return {}
      default:
        return state;
    }
  };

  export const editFaqReducer = (
    state = { loading: true, success: false },
    action
  ) => {
    switch (action.type) {
      case EDIT_FAQ_REQUEST:
        return { ...state, loading: true };
      case EDIT_FAQ_SUCCESS:
        return { loading: false, success: true };
      case EDIT_FAQ_FAIL:
        return { loading: false, error: action.payload };
      case EDIT_FAQ_RESET:
        return {}
      default:
        return state;
    }
  };