import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate,useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { addSpa, editSpaAction, getSpa, getSpaById } from '../actions/SpaAction';
import AdminNavbar from '../components/AdminNavbar';
import LoadingBox from '../components/LoadingBox';
import { EDIT_SPA_RESET, SPA_CREATE_RESET } from '../constants/Spa';
import { showSuccessNotification } from '../components/PopupBox';
import BlogEditor from '../components/BlogEditor';
import { convertToRaw, EditorState,ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Button } from '@chakra-ui/react';

function AddSpa() {
  const [title, setTitle] = useState('');
  // const [datas, setDatas] = useState('');
  const [avatar, setAvatar] = useState('');
  const [serviceOptions, setServiceOptions] = useState([]);
  const [previewSource, setPreviewSource] = useState('');
  const getSpas=useSelector(state=>state.fetchSpa)
  const {loading:loadingSpa,error,spas,data}=getSpas
  const editSpa=useSelector((state)=>state.editSpa)
  const {success:successEdit}= editSpa
  const [note, setNote] = useState('')
  const { id } = useParams();

    const [editorState, setEditorState] = useState([]);
    const [rawContentState, setRawContentState] = useState({});
    const [htmlContent, setHtmlContent] = useState([]);

    
    const [apiLoading, setApiLoading] = useState(false)

  
   
  const dispatch = useDispatch();

  const createSpa = useSelector((state) => state.createSpa);
  const { success, loading } = createSpa;
  const navigate = useNavigate();

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setAvatar(reader.result);
  };
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };

  useEffect(()=>{
    if(id ){
      dispatch(getSpaById(id))
     
    
    }
  },[])


  // useEffect(()=>{
  //   if(data){
  //     setServiceOptions(spas?.data?.categories)
  //     setTitle(spas?.data?.serviceTitle)
  //     setPreviewSource(spas?.data?.image)
  //   }

  // },[data])
  const [editorStates, setEditorStates] = useState([]);
const [htmlContents, setHtmlContents] = useState([]);

  
  // useEffect(() => {
  //   if (data) {
  //     setServiceOptions(spas?.data?.categories);
  //     setTitle(spas?.data?.serviceTitle);
  //     setPreviewSource(spas?.data?.image);
  //     console.log(spas?.data?.categories,'spas?.data?.categories')
  
  //     // Initialize editorStates and htmlContents arrays based on the number of service options
  //     const numOptions = spas?.data?.categories.length || 0;
  //     const initialEditorStates = Array(numOptions).fill(EditorState.createEmpty());
  //     const initialHtmlContents = Array(numOptions).fill('');

  //      console.log(initialEditorStates,'initialEditorStates');
  //      console.log(initialHtmlContents,'initialEditorStates');

  
  //     setEditorStates(initialEditorStates);
  //     setHtmlContents(initialHtmlContents);
  //   }
  // }, [data]);

  useEffect(() => {
    if (data) {
      const categories = spas?.data?.categories || []; // Ensure categories is an array
  
      // Initialize editorStates and htmlContents arrays based on the number of service options
      const initialEditorStates = categories.map((category) => {
        const htmlContent = category.categoryDescription || ''; // Get the HTML content from the category
        const contentState = ContentState.createFromBlockArray(htmlToDraft(htmlContent).contentBlocks);
        return EditorState.createWithContent(contentState);
      });
  
      const initialHtmlContents = categories.map((category) => category.categoryDescription || '');
  
      setServiceOptions(categories);
      setTitle(spas?.data?.serviceTitle);
      setPreviewSource(spas?.data?.image);
      setEditorStates(initialEditorStates);
      setHtmlContents(initialHtmlContents);
      setNote(spas?.data?.note)
    }
  }, [data]);
  
  


  

  useEffect(() => {
    if (success) {
      showSuccessNotification('Spa created Successfully.');
      dispatch({ type: SPA_CREATE_RESET });
      navigate('/spa');
    }
    if (successEdit) {
      showSuccessNotification('Spa Edited Successfully.');
      dispatch({ type: EDIT_SPA_RESET });
      navigate('/spa');
    }
  }, [success, successEdit]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setApiLoading(true)
    if(!id){
    if (
      !title ||
  
      !avatar ||
      serviceOptions.some(
        (option) =>
          !option.categoryTitle ||
          !option.categoryDescription ||
          option.packages.length === 0 ||
          option.packages.some((packageItem) => !packageItem.duration || !packageItem.price)
      )
    ) {
      Swal.fire({
        title: 'Please fill all the required fields',
        text: 'Thanks',
        type: 'success',
        icon: 'error',
      });
      
    setApiLoading(false)
    } else {
    dispatch(addSpa(title,avatar,serviceOptions,note))
     .then(() => {
          Swal.fire({
            title: 'Spa Added Successfully',
            text: "Thanks",
            type: 'success',
            icon: 'success',
          });
          navigate('/spa');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setApiLoading(false);
        });

    }
  }else{
    const data = {
      spa_id: id,
      serviceTitle: title,
      note:note,
      categories: serviceOptions.map(category => {
        const modifiedCategory = { ...category };
        
        if (modifiedCategory._id) {
          modifiedCategory.category_id = modifiedCategory._id;
          delete modifiedCategory._id;
        }
    
        if (modifiedCategory.packages) {
          modifiedCategory.packages = modifiedCategory.packages.map(packageItem => {
            const modifiedPackage = { ...packageItem };
    
            if (modifiedPackage._id) {
              modifiedPackage.package_id = modifiedPackage._id;
              delete modifiedPackage._id;
            }
    
            return modifiedPackage;
          });
        }
    
        return modifiedCategory;
      })
    };
    
    if (avatar) {
      data.image = avatar;
    }
    console.log(data,'data')
    dispatch(editSpaAction(data))
      .then(() => {
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setApiLoading(false);
      });
  }
  };

  const addServiceOption = () => {
    setServiceOptions([...serviceOptions, { categoryTitle: '', categoryDescription: '', packages: [] }]);
  };

  const handleServiceTitleChange = (index, value) => {
    const updatedOptions = [...serviceOptions];
    updatedOptions[index].categoryTitle = value;
    setServiceOptions(updatedOptions);
  };

  const handleServiceDescriptionChange = (index, value) => {
    const updatedOptions = [...serviceOptions];
    updatedOptions[index].categoryDescription = value;
    setServiceOptions(updatedOptions);
  };

  const addPackage = (index) => {
    const updatedOptions = [...serviceOptions];
    updatedOptions[index].packages.push({ duration: '', price: '' });
    setServiceOptions(updatedOptions);
  };

  const removePackage = (optionIndex, packageIndex) => {
    const updatedOptions = [...serviceOptions];
    updatedOptions[optionIndex].packages.splice(packageIndex, 1);
    setServiceOptions(updatedOptions);
  };

  const handlePackageDurationChange = (optionIndex, packageIndex, value) => {
    const updatedOptions = [...serviceOptions];
    updatedOptions[optionIndex].packages[packageIndex].duration = value;
    setServiceOptions(updatedOptions);
  };

  const handlePackagePriceChange = (optionIndex, packageIndex, value) => {
    const updatedOptions = [...serviceOptions];
    updatedOptions[optionIndex].packages[packageIndex].price = value;
    setServiceOptions(updatedOptions);
  };


//   const onEditorStateChange = (index,newEditorState) => {
//     const tempEditorState = [...editorState]
//     tempEditorState[index] = newEditorState
//     setEditorState(tempEditorState);
//     const content = newEditorState.getCurrentContent();
//     setRawContentState(convertToRaw(content));
//     const html = draftToHtml(convertToRaw(content));
//     const TempHtmlContent = [...htmlContent]
//     TempHtmlContent[index] = html
//     setHtmlContent(TempHtmlContent);
//     handleServiceDescriptionChange(index,html)
// };

const onEditorStateChange = (index, newEditorState) => {
  const updatedEditorStates = [...editorStates];
  updatedEditorStates[index] = newEditorState;
  setEditorStates(updatedEditorStates);

  const content = newEditorState.getCurrentContent();
  const html = draftToHtml(convertToRaw(content));

  const updatedHtmlContents = [...htmlContents];
  updatedHtmlContents[index] = html;
  setHtmlContents(updatedHtmlContents);

  // Update the service description for the corresponding service option
  handleServiceDescriptionChange(index, html);
};

console.log(serviceOptions,'htmlContent')

  return (
    <div>
      <AdminNavbar>
        <div className="write">
          {previewSource && <img className="writeImg" src={previewSource} alt="chosen" />}
          <form className="writeForm" onSubmit={handleSubmit}>
            <div className="writeFormGroup">
              <input
                className="writeInput"
                placeholder="Title"
                type="text"
                autoFocus={true}
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
            </div>
            <div className="writeFormGroup writeInput">
              <label htmlFor="fileInput" style={{ display: 'flex' }}>
                Upload Image
                <i className="writeIcon fas fa-plus" style={{ marginLeft: '20px' }}></i>
              </label>
              <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
              />
            </div>
            <br />

              <input
                type="text"
                placeholder='Add Special Instruction here Seperated By Comma'
                class="writeInput"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            {/* <div className="writeFormGroup">
              <textarea
                className="writeInput writeText"
                placeholder="Description (optional)"
                type="text"
                autoFocus={true}
                onChange={(e) => setDatas(e.target.value)}
              />
            </div> */}
            {serviceOptions.map((option, index) => (
              <div key={index}>
                <div className="writeFormGroup">
                  <input
                    className="writeInput"
                    placeholder="Service Title"
                    type="text"
                    value={option.categoryTitle}
                    onChange={(e) => handleServiceTitleChange(index, e.target.value)}
                  />
                </div>
                {/* <div className="writeFormGroup"> */}
                  {/* <textarea
                    className="writeInput writeText"
                    placeholder="Service Description"
                    type="text"
                    value={option.categoryDescription}
                    onChange={(e) => handleServiceDescriptionChange(index, e.target.value)}
                  /> */}
                  {/* <BlogEditor
                    editorState={editorState[index]}
                    onEditorStateChange={(data)=>{onEditorStateChange(index,data)}}
                    htmlContent={htmlContent[index]}
                  /> */}
                   <BlogEditor
                      editorState={editorStates[index]}
                      onEditorStateChange={(data) => onEditorStateChange(index, data)}
                      htmlContent={htmlContents[index]}
                    />
                {/* </div> */}
                <div>
                
                  {option.packages.map((packageItem, packageIndex) => (
                    <div key={packageIndex} className='writeFormGroup packageInputGrp'>
                      <label>Duration</label>
                      <input
                        placeholder="Duration"
                        type="text"
                        value={packageItem.duration}
                        onChange={(e) =>
                          handlePackageDurationChange(index, packageIndex, e.target.value)
                        }
                      />
                      <label>Price</label>
                      <input
                        placeholder="Price"
                        type="text"
                        value={packageItem.price}
                        onChange={(e) =>
                          handlePackagePriceChange(index, packageIndex, e.target.value)
                        }
                      />
                      <button type='button'  className='add-btn' onClick={() => removePackage(index, packageIndex)}>Remove Package</button>
                    </div>
                  ))}
                    <button className='add-btn' style={{marginLeft:"150px",marginTop:"50px"}} type='button' onClick={() => addPackage(index)}>Add Package</button>
                </div>
              </div>
            ))}
            <button className='add-btn' type="button"  style={{marginLeft:"150px"}}  onClick={addServiceOption}>Add Service Option</button>
            <Button className="writeSubmit" style={{position:"absolute",background:"teal"}} type="submit" isLoading={apiLoading}
              loadingText="Submitting" >Publish
              </Button>
            {loading && <LoadingBox />}
          </form>
        </div>
      </AdminNavbar>
    </div>
  );
}

export default AddSpa;
