import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import BlogScreen from "./screens/BlogScreen";
import "./styles/globals.css";
import AdminNavbar from "./components/AdminNavbar";
import { ChakraProvider } from "@chakra-ui/react";
import BannerImage from "./screens/BannerImage";
import GalleryScreen from "./screens/GalleryScreen";
import AddBlog from "./screens/AddBlog";
import ReviewScreen from "./screens/ReviewScreen";
import AddReview from "./screens/AddReview";
import BannerScreen from "./screens/BannerScreen";
import AddGallery from "./screens/AddGallery";
import AddBanner from "./screens/AddBanner";
import HomeSliderScreen from "./screens/HomeSliderScreen";
import AddHomeSlider from "./screens/AddHomeSlider";
import FaqScreen from "./screens/FaqScreen";
import AddFaq from "./screens/AddFaq";
import LoginScreen from "./screens/LoginScreen";
import AdminRoute from "./components/AdminRoute";
import AddBanners from "./screens/AddBanners";
import FormScreen from "./screens/FormScreen";
import SpaScreen from "./screens/SpaScreen";
import AddSpa from "./screens/AddSpa";
import BookedUser from "./screens/bookedUser";
import AhlanScreen from "./screens/AhlanScreen";
import AddAhlan from "./screens/AddAhlan";
import AhlanUser from "./screens/AhlanUser";
import AhlanLoungeScreen from "./screens/AhlanLoungeScreen";
import AddAhlanLounge from "./screens/AddAhlanLounge";
import AhlanLoungeUser from "./screens/AhlanLoungeUser";
import TierScreen from "./screens/TierScreen";
import EditTierScreen from "./screens/EditTierScreen";
import AddTier from "./screens/AddTier";
import TimeRestrictionScreen from "./screens/TimeRestrictionScreen";
import AddTimeRestrictionScreen from "./screens/AddTimeRestrictionScreen";
import TAScreen from "./screens/TravelAgencyScreen";
import UserScreen from "./screens/UsersScreens";
import DisabledUserScreen from "./screens/DisabledUsersScreens";

function App() {
  return (
    <div className={"adminContainer"}>
      <ChakraProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginScreen />} />
            <Route
              path="/banner-image"
              element={
                <AdminRoute>
                  <BannerImage />
                </AdminRoute>
              }
            >
              {" "}
            </Route>

            <Route
              path="/blog"
              element={
                <AdminRoute>
                  <BlogScreen />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/add-blog"
              element={
                <AdminRoute>
                  <AddBlog />
                </AdminRoute>
              }
            >
              {" "}
            </Route>

            <Route
              path="/edit-blog/:id"
              element={
                <AdminRoute>
                  <AddBlog />
                </AdminRoute>
              }
            >
              {" "}
            </Route>

            <Route
              path="/gallery"
              element={
                <AdminRoute>
                  <GalleryScreen />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/add-gallery"
              element={
                <AdminRoute>
                  <AddGallery />
                </AdminRoute>
              }
            >
              {" "}
            </Route>

            <Route
              path="/review"
              element={
                <AdminRoute>
                  <ReviewScreen />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/add-review"
              element={
                <AdminRoute>
                  <AddReview />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/edit-review/:id"
              element={
                <AdminRoute>
                  <AddReview />
                </AdminRoute>
              }
            >
              {" "}
            </Route>

            <Route
              path="/banner"
              element={
                <AdminRoute>
                  <BannerScreen />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/add-banner"
              element={
                <AdminRoute>
                  <AddBanner />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/add-banners"
              element={
                <AdminRoute>
                  <AddBanners />
                </AdminRoute>
              }
            >
              {" "}
            </Route>

            <Route
              path="/homeslider"
              element={
                <AdminRoute>
                  <HomeSliderScreen />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/add-homeslider"
              element={
                <AdminRoute>
                  <AddHomeSlider />
                </AdminRoute>
              }
            >
              {" "}
            </Route>

            <Route
              path="/faq"
              element={
                <AdminRoute>
                  <FaqScreen />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/add-faq"
              element={
                <AdminRoute>
                  <AddFaq />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/edit-faq/:id"
              element={
                <AdminRoute>
                  <AddFaq />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/spa"
              element={
                <AdminRoute>
                  <SpaScreen />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/add-spa"
              element={
                <AdminRoute>
                  <AddSpa />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/edit-spa/:id"
              element={
                <AdminRoute>
                  <AddSpa />
                </AdminRoute>
              }
            >
              {" "}
            </Route>

            <Route
              path="/form"
              element={
                <AdminRoute>
                  <FormScreen />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/booked-user"
              element={
                <AdminRoute>
                  <BookedUser />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/ahlan-user"
              element={
                <AdminRoute>
                  <AhlanUser />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/ahlan"
              element={
                <AdminRoute>
                  <AhlanScreen />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/add-ahlan"
              element={
                <AdminRoute>
                  <AddAhlan />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/edit-ahlan/:id"
              element={
                <AdminRoute>
                  <AddAhlan />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/ahlan-lounge"
              element={
                <AdminRoute>
                  <AhlanLoungeScreen />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/add-ahlan-lounge"
              element={
                <AdminRoute>
                  <AddAhlanLounge />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/edit-ahlan-lounge/:id"
              element={
                <AdminRoute>
                  <AddAhlanLounge />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/ahlan-lounge-user"
              element={
                <AdminRoute>
                  <AhlanLoungeUser />
                </AdminRoute>
              }
            >
              {" "}
            </Route>
            <Route
              path="/tier"
              element={
                <AdminRoute>
                  <TierScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/users"
              element={
                <AdminRoute>
                  <UserScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/disabled-users"
              element={
                <AdminRoute>
                  <DisabledUserScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/tier-update"
              element={
                <AdminRoute>
                  <EditTierScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/add-tier"
              element={
                <AdminRoute>
                  <AddTier />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/timerestriction"
              element={
                <AdminRoute>
                  <TimeRestrictionScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/add-timerestriction"
              element={
                <AdminRoute>
                  <AddTimeRestrictionScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/edit-timerestriction/:id"
              element={
                <AdminRoute>
                  <AddTimeRestrictionScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/travelagency-users"
              element={
                <AdminRoute>
                  <TAScreen />
                </AdminRoute>
              }
            ></Route>
          </Routes>
          {/* </AdminNavbar> */}
        </BrowserRouter>
      </ChakraProvider>
    </div>
  );
}

export default App;
