export const TIER_CREATE_REQUEST = "TIER_CREATE_REQUEST";
export const TIER_CREATE_SUCCESS = "TIER_CREATE_SUCCESS";
export const TIER_CREATE_FAIL = "TIER_CREATE_FAIL";
export const TIER_CREATE_RESET = "TIER_CREATE_RESET";

export const FETCH_TIER_REQUEST = "FETCH_TIER_REQUEST";
export const FETCH_TIER_SUCCESS = "FETCH_TIER_SUCCESS";
export const FETCH_TIER_FAIL = "FETCH_TIER_FAIL";

export const DELETE_TIER_REQUEST = "DELETE_TIER_REQUEST";
export const DELETE_TIER_SUCCESS = "DELETE_TIER_SUCCESS";
export const DELETE_TIER_FAIL = "DELETE_TIER_FAIL";
export const DELETE_TIER_RESET = "DELETE_TIER_RESET";

export const Url = "https://dih-node-vercel-production-3413.up.railway.app";
