export const AHLAN_CREATE_REQUEST = "AHLAN_CREATE_REQUEST";
export const AHLAN_CREATE_SUCCESS = "AHLAN_CREATE_SUCCESS";
export const AHLAN_CREATE_FAIL = "AHLAN_CREATE_FAIL";
export const AHLAN_CREATE_RESET = "AHLAN_CREATE_RESET";

export const FETCH_AHLAN_REQUEST = "FETCH_AHLAN_REQUEST";
export const FETCH_AHLAN_SUCCESS = "FETCH_AHLAN_SUCCESS";
export const FETCH_AHLAN_FAIL = "FETCH_AHLAN_FAIL";

export const DELETE_AHLAN_REQUEST = "DELETE_AHLAN_REQUEST";
export const DELETE_AHLAN_SUCCESS = "DELETE_AHLAN_SUCCESS";
export const DELETE_AHLAN_FAIL = "DELETE_AHLAN_FAIL";
export const DELETE_AHLAN_RESET = "DELETE_AHLAN_RESET";

export const EDIT_AHLAN_REQUEST = "EDIT_AHLAN_REQUEST";
export const EDIT_AHLAN_SUCCESS = "EDIT_AHLAN_SUCCESS";
export const EDIT_AHLAN_FAIL = "EDIT_AHLAN_FAIL";
export const EDIT_AHLAN_RESET = "EDIT_AHLAN_RESET";


export const FETCH_AHLAN_DETAILS_REQUEST = "FETCH_AHLAN_DETAILS_REQUEST";
export const FETCH_AHLAN_DETAILS_SUCCESS = "FETCH_AHLAN_DETAILS_SUCCESS";
export const FETCH_AHLAN_DETAILS_FAIL = "FETCH_AHLAN_DETAILS_FAIL";
export const FETCH_AHLAN_DETAILS_RESET = "FETCH_AHLAN_DETAILS_RESET";


// export const Url='https://dih-backend.herokuapp.com'
// export const Url='https://dih-backend.up.railway.app'
export const Url = "https://dih-node-vercel-production-3413.up.railway.app";
