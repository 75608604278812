import {
  Box,
  Flex,
  Heading,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Button,
  ModalFooter,
  Input,
  Image,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import styles from "../styles/AdminSubPage.module.css";
import Breadcrumb from "../components/Breadcrumb";


const BannerImage = () => {
  
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState("");

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();

   const imgUpload = (event) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("upload_preset", "mint-event-rentals");

    const requestOptions = {
      method: "POST",
      body: formData,
    };
    fetch(
      "https://api.cloudinary.com/v1_1/mccollins-media/image/upload",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setPhoto(data.secure_url);
        
        setLoading(false);
      });
  };

  return (
    <Box as="section" className={styles.main}>
      <Breadcrumb BC="Dashboard &gt; contacts" />
      <Box className={styles.mainContainer}>
        <Flex className={styles.mainHeading}>
          <Heading>Newslatter List</Heading>
          <Button
            colorScheme="teal"
            onClick={(e) => {
              onUploadOpen(e)
            }}
          >
            Add Catalog
          </Button>
        </Flex>
        <Box className={styles.tableBox}>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>SI NO:</Th>
                  <Th>Email</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                    <Tr >
                      <Td>1</Td>
                      <Td>salman@gmail.com</Td>
                      <Td>
                        <Flex justifyContent={"start"}>
                          <AiFillDelete
                            size={"25px"}
                            style={{ cursor: "pointer" }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>SI NO:</Th>
                  <Th>Email</Th>
                  <Th>Action</Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Modal isOpen={isUploadOpen} onClose={onUploadClose}>
        <ModalOverlay />
        <ModalContent>
            <Image src={photo} width="300px" height="300px"/>
          <ModalHeader>Edit Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Please upload your latest Image</Text>
            <Input type={"file"} onChange={()=>{imgUpload()}} />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="teal"
                  isLoading={loading}
              mr={3}
            >
              Upload
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};


export default BannerImage;
