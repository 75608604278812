import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { addBlog, editBlogAction, getBlog, getBlogById } from '../actions/UploadAction';
import AdminNavbar from '../components/AdminNavbar';
import LoadingBox from '../components/LoadingBox';
import { BLOG_CREATE_RESET, EDIT_BLOG_RESET, Url } from '../constants/Blog';
import BlogEditor from '../components/BlogEditor';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";





function AddBlog() {

    const [title, setTitle] = useState('')
    const [datas, setDatas] = useState('')
    const [avatar, setAvatar] = useState('')
    const [fileInputState, setFileInputState] = useState('');
    const [previewSource, setPreviewSource] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const getBlogs=useSelector(state=>state.fetchBlog)
    const {loading:loadingBlog,error:errorBlog,blogs,data}=getBlogs
    const updateState=useSelector((state)=>state.editBlog)
    const {loading:loadingEdit,error:errorEdit,success:successEdit}=updateState
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [htmlContent, setHtmlContent] = useState('');
    const [rawContentState, setRawContentState] = useState({});



   
    const dispatch=useDispatch()
    const { id } = useParams();


    const createBlog=useSelector(state=>state.createBlog)
    const {success,loading}=createBlog
    const navigate=useNavigate()

    // useEffect(()=>{
    //   dispatch(getBlog())
    //   if(id && blogs){
    //     if(blogs.data.length>0){
        
    //     const filterBlogs=blogs.data.find((e)=>e._id == id)
    //     console.log(filterBlogs,'sap',id,'id');
    //     setTitle(filterBlogs.title)
    //     setPreviewSource(filterBlogs.avatar)
    //     setDatas(filterBlogs.data)
    //   }
    //   }
    // },[])

    const onEditorStateChange = (newEditorState) => {
      setEditorState(newEditorState);
      const content = newEditorState.getCurrentContent();
      setRawContentState(convertToRaw(content));
      const html = draftToHtml(convertToRaw(content));
      setHtmlContent(html);
    };


    useEffect(()=>{
      if( id){
       dispatch(getBlogById(id)) 
       console.log('heyyy')

      }
    },[dispatch,id])

    

    useEffect(()=>{
      if(data){
        setTitle(blogs.data.title)
        setPreviewSource(blogs.data.avatar)
        // setDatas(blogs.data.data)
        
    
        setHtmlContent(blogs?.data?.data)
        
    
        const htmlContent = blogs?.data?.data;
    
        // Convert the HTML content to Draft.js ContentState
        const blocksFromHTML = convertFromHTML(htmlContent);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
    
        // Set the initial EditorState with the ContentState
        setEditorState(EditorState.createWithContent(contentState));
      }
    },[data])

    // if(blogs){
      
    // }



    const handleFileInputChange = (e) => {
      const file = e.target.files[0];
      previewFile(file);
      setSelectedFile(file);
      setFileInputState(e.target.value);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setAvatar(reader.result);
    };
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
        setPreviewSource(reader.result);
    };
};

useEffect(()=>{
  
  if(success){
    
    Swal.fire({
      title: 'blog created Succesfully.',
      text: "Thanks",
      type: 'success',    
      icon: 'success',        
    }); 
    dispatch({type:BLOG_CREATE_RESET})
    navigate('/blog')
  }
  if(successEdit){
    Swal.fire({
      title: 'blog created Succesfully.',
      text: "Thanks",
      type: 'success',    
      icon: 'success',        
    }); 
    dispatch({type:EDIT_BLOG_RESET})
    navigate('/blog')
  }

},[success,successEdit])


const handleSubmit=async(e)=>{
  e.preventDefault()
 if(!id){
  if(!title){
    console.log('no title');
    Swal.fire({
      title: 'please type title.',
      text: "Thanks",
      type: 'success',    
      icon: 'error',        
    }); 
  
  }else if(!avatar){
    console.log('no titleaaa');

    Swal.fire({
      title: 'please select image.',
      text: "Thanks",
      type: 'success',    
      icon: 'error',        
    }); 
  }else{
    console.log('no title');

    dispatch(addBlog(title,htmlContent,avatar))

  }
}else{
  const data={
    blog_id:id,
    title,
    data:htmlContent,
  }
  if(avatar){
    data.avatar=avatar
  }
  console.log(data)
  dispatch(editBlogAction(data))
}
}



  return (
    <div>
      <AdminNavbar>
        <div className="write">
        {previewSource && (
                <img
                     className="writeImg"
                    src={previewSource}
                    alt="chosen"
                    // style={{ height: '300px' }}
                />
            )}
      <form className="writeForm" onSubmit={handleSubmit}>
        <div className="writeFormGroup">
          <label htmlFor="fileInput">
            <i className="writeIcon fas fa-plus"></i>
          </label>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            // onChange={(e) => setFile(e.target.files[0])}
            onChange={handleFileInputChange}
          />
          <input
            className="writeInput"
            placeholder="Title"
            type="text"
            value={title}
            autoFocus={true}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        {/* <div className="writeFormGroup"> */}
          {/* <textarea
            className="writeInput writeText"
            placeholder="Tell your story..."
            type="text"
            value={datas}
            autoFocus={true}
            onChange={(e) => setDatas(e.target.value)}
          /> */}
           <BlogEditor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        htmlContent={htmlContent}
      />
        {/* </div> */}
        <button className="writeSubmit" type="submit">
          Publish
        </button>
        {(loading || loadingEdit) && <LoadingBox></LoadingBox>}
      </form>
    </div>
    </AdminNavbar>
    </div>
  )
}

export default AddBlog