import React, { useEffect, useState } from 'react'
import {
  Box,
  Heading,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import styles from "../styles/Dashboard.module.css";
import Swal from 'sweetalert2';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import AdminNavbar from '../components/AdminNavbar';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditTierScreen = () => {


  const navigate=useNavigate()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tierId = queryParams.get('tierId');
  const serviceName = queryParams.get('serviceName');

  
  const [services, setServices] = useState();
    
  const [formData, setFormData] = useState([]);
  const [ahlanLoungeFormData, setAhlanLoungeFormData] = useState([]);
  const [spaFormData, setSpaFormData] = useState([]);

 useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://dih-node-vercel-production-3413.up.railway.app/api/v1/tier/${tierId}`);
        setServices(response.data.data);
        console.log(response.data.data);
        const initialFormData = response.data.data.ahlan.map(fq => ({
          service: fq.service._id,
          adult: fq.adult || fq.service.adult,
          extra: fq.extra || fq.service.extra,
          group: fq.group || fq.service.group,
          children: fq.children || fq.service.children,
        }));
        setFormData(initialFormData);

        const initialAhlanLoungeFormData = response.data.data.ahlan_lounge.map(fq => ({
            service: fq.service._id,
            adult: fq.adult || fq.service.adult,
            extra: fq.extra || fq.service.extra,
            group: fq.group || fq.service.group,
            children: fq.children || fq.service.children,
        }));
        setAhlanLoungeFormData(initialAhlanLoungeFormData);

 

        const initialSpaFormData = response.data.data.spa.map(fq => ({
            service: fq.service._id,
            categories: fq.service.categories.map(cat => {
                const packages = cat.packages.map(pkg => ({
                    packageId: pkg._id,
                    price: pkg.price || cat.packages[0].price || fq.service.categories[0].packages[0].price
                }));
                console.log(response.data.data.spa,'spaaaa')
                if (fq.categories && fq.categories.length > 0) {
                    const matchingCategory = fq.categories.find(category => category.categoryId === cat._id);
                    if (matchingCategory && matchingCategory.packages && matchingCategory.packages.length > 0) {
                        for (const matchingPackage of matchingCategory.packages) {
                            const packageIndex = packages.findIndex(pkg => pkg.packageId === matchingPackage.packageId);
                            if (packageIndex !== -1 && matchingPackage.price) {
                                packages[packageIndex].price = matchingPackage.price;
                            }
                        }
                    }
                }

                return {
                    categoryId: cat._id,
                    packages: packages
                };
            })
        }));



        setSpaFormData(initialSpaFormData);


        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


    fetchData();
  }, []);

        console.log(services?.spa[0],'services');


  const handleInputChange = (event, index, field) => {
    const { value } = event.target;
    const updatedFormData = [...formData];
    updatedFormData[index][field] = parseFloat(value);
    setFormData(updatedFormData);
    console.log(formData);
  };

    const handleInputChangeAhlanLounge = (event, index, field) => {
        const { value } = event.target;
        const updatedAhlanLoungeFormData = [...ahlanLoungeFormData];
        updatedAhlanLoungeFormData[index][field] = parseFloat(value);
        setAhlanLoungeFormData(updatedAhlanLoungeFormData);
    };

    const spaHandleInputChange = (event, serviceIndex, categoryIndex, packageIndex) => {
        const { value } = event.target;

        // Update the spaFormData state immutably
        const updatedSpaFormData = [...spaFormData];
        updatedSpaFormData[serviceIndex].categories[categoryIndex].packages[packageIndex].price = parseFloat(value);
        setSpaFormData(updatedSpaFormData);
        
        console.log(spaFormData,"this is spa change");
        
    };

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const dataToSubmit = {
        ahlan: serviceName === "meet" ? formData : [],
        ahlan_lounge: serviceName === "lounge" ? ahlanLoungeFormData : [], // You can add lounge data here
        spa: [], // You can add spa data here
      };

      const response = await axios.post(`https://dih-node-vercel-production-3413.up.railway.app/api/v1/tier/${tierId}/add-data`, dataToSubmit);
      console.log('API response:', response.data);
      Swal.fire({
        title: "Tier Rate Changed Successfully.",
        text: "Thanks",
        icon: "success",
      });
      navigate("/tier");
      // You might want to show a success message to the user here
    } catch (error) {
      console.error('Error submitting data:', error);
      Swal.fire({
        title: "Tier Rate Not Changed.",
        text: "Sorry",
        icon: "error",
      });
      // You might want to show an error message to the user here
    }
  };


  const handleSubmitSpa = async (e) => {
    e.preventDefault();
    try {

        const dataToSubmit1 = {
            ahlan:  [],
            ahlan_lounge:  [], // You can add lounge data here
            spa: spaFormData, // You can add spa data here
        };


        console.log(spaFormData);
        const response = await axios.post(`https://dih-node-vercel-production-3413.up.railway.app/api/v1/tier/${tierId}/add-data`, dataToSubmit1);
        console.log('API response for spa:', response.data);
            if (response.status === 200) {
            Swal.fire({
                title: "Rate is Changed Successfully.",
                text: "Thanks",
                icon: "success",
            });
            navigate("/tier");
            }
        // You might want to show a success message to the user here
    } catch (error) {
        console.error('Error submitting spa data:', error);
        // You might want to show an error message to the user here
        }
    };



  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  return (
        <div>
      <AdminNavbar>
      <Box as="section">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
            {serviceName === "meet" && (
                <GridItem
                    colSpan={{ base: 4, xl: 4 }}
                    className={styles.recentOrders}
                >
                    <Box className={styles.cardHeader}>
                    <Heading>Ahlan Meet & Greet Rate</Heading>
                    {/* <button className={styles.adminBtn} onClick={handleSubmit}>Submit</button> */}
                    </Box>
                    <form>
                    <TableContainer className={styles.tableContainer}>
                    <Table variant="simple" size="lg">
                        <Thead>
                        <Tr>
                            <Th style={{width:'20%'}}>Image</Th>
                            <Th style={{width:'40%'}}>Title</Th>
                            <Th style={{width:'40%'}}>Price</Th>
                            {/* <Th style={{width:'8%'}}>Action</Th> */}
                        </Tr>
                        </Thead>
                        {
                        services && services.ahlan.map((fq,index)=>(
                        <Tbody key={fq.service._id}>
                        <Tr>
                            <Td><img src={fq.service.image} alt="" /></Td>
                            {/* <Td>{truncate(fq.service.title,25)}</Td> */}
                            <Td>{fq.service.title}</Td>
                            <Td>Adult :  
                                <input
                                type="number"
                                name='adult'
                                value={formData[index]?.adult}
                                onChange={(e) => handleInputChange(e, index, 'adult')}
                                /> <br/>
                                Extra :
                                <input
                                type="number"
                                name='extra'
                                value={formData[index]?.extra}
                                onChange={(e) => handleInputChange(e, index, 'extra')}
                                />
                                <br/> 
                                Group : 
                                <input
                                type="number"
                                name='group'
                                value={formData[index]?.group}
                                onChange={(e) => handleInputChange(e, index, 'group')}
                                />
                                <br/> 
                                Children : 
                                <input
                                type="number"
                                name='children'
                                value={formData[index]?.children}
                                onChange={(e) => handleInputChange(e, index, 'children')}
                                />
                                </Td>
                            {/* <Td>
                            <span className={styles.inProgress} style={{cursor:'pointer'}} onClick={()=>console.log(fq._id)}>Submit</span>
                            </Td> */}
                            
                        </Tr>
                        </Tbody>
                        ))}
                        
                    </Table>
                    </TableContainer>
                    <Box className={styles.cardHeader} style={{justifyContent:"end",marginTop:"10px"}}>
                    <button className={styles.adminBtn} onClick={handleSubmit}>Submit</button>
                    </Box>
                    </form>
                </GridItem>
            )}

            {serviceName === "lounge" && (
            <GridItem
                    colSpan={{ base: 4, xl: 4 }}
                    className={styles.recentOrders}
                >
                    <Box className={styles.cardHeader}>
                    <Heading>Ahlan Lounge Rate</Heading>
                    {/* <button className={styles.adminBtn} onClick={handleSubmit}>Submit</button> */}
                    </Box>
                    <form>
                    <TableContainer className={styles.tableContainer}>
                    <Table variant="simple" size="lg">
                        <Thead>
                        <Tr>
                            <Th style={{width:'20%'}}>Image</Th>
                            <Th style={{width:'40%'}}>Title</Th>
                            <Th style={{width:'40%'}}>Price</Th>
                            {/* <Th style={{width:'8%'}}>Action</Th> */}
                        </Tr>
                        </Thead>
                        {
                        services && services.ahlan_lounge.map((fq,index)=>(
                        <Tbody key={fq.service._id}>
                        <Tr>
                            <Td><img src={fq.service.image} alt="" /></Td>
                            <Td>{fq.service.title}</Td>
                            {/* <Td>{truncate(fq.service.title,25)}</Td> */}
                            <Td>Adult :  
                                <input
                                type="number"
                                name='adult'
                                value={ahlanLoungeFormData[index]?.adult}
                                onChange={(e) => handleInputChangeAhlanLounge(e, index, 'adult')}
                                /> <br/>
                                Extra :
                                <input
                                type="number"
                                name='extra'
                                value={ahlanLoungeFormData[index]?.extra}
                                onChange={(e) => handleInputChangeAhlanLounge(e, index, 'extra')}
                                />
                                <br/> 
                                Group : 
                                <input
                                type="number"
                                name='group'
                                value={ahlanLoungeFormData[index]?.group}
                                onChange={(e) => handleInputChangeAhlanLounge(e, index, 'group')}
                                />
                                <br/> 
                                Children : 
                                <input
                                type="number"
                                name='children'
                                value={ahlanLoungeFormData[index]?.children}
                                onChange={(e) => handleInputChangeAhlanLounge(e, index, 'children')}
                                />
                                </Td>
                            {/* <Td>
                            <span className={styles.inProgress} style={{cursor:'pointer'}} onClick={()=>console.log(fq._id)}>Submit</span>
                            </Td> */}
                            
                        </Tr>
                        </Tbody>
                        ))}
                        
                    </Table>
                    </TableContainer>
                    <Box className={styles.cardHeader} style={{justifyContent:"end",marginTop:"10px"}}>
                    <button className={styles.adminBtn} onClick={handleSubmit}>Submit</button>
                    </Box>
                    </form>
                </GridItem>
            )}

            {serviceName === "spa" && (
                <>
                {services && services.spa.map((service, serviceIndex) => (
                    <GridItem
                    colSpan={{ base: 4, xl: 4 }}
                    className={styles.recentOrders}
                    minHeight={"auto"}
                     key={service._id}
                >
                    <Box className={styles.cardHeader}>
                        <Heading>{service.service.serviceTitle}</Heading>
                        </Box>
                        {service.categories?.map((category, categoryIndex) => (
                            <TableContainer className={styles.tableContainer} key={category.categoryId}>
                                <Table variant="simple" size="lg">
                            {category.packages.map((pkg, packageIndex) => {
                                let price2 = 0; 
                                let duration = 0;
                                let catTitle = ""
                                let count = 0;
                                service.service.categories?.forEach(category1 => {
                                    category1.packages.forEach(pkgFromService => {
                                        if (pkgFromService._id === pkg.packageId) {
                                            price2 = pkgFromService.price;
                                            duration = pkgFromService.duration;
                                            catTitle = category1.categoryTitle;
                                        }
                                    });
                                });
                                return (
                                    <>
                                        {categoryIndex === 0 && packageIndex === 0 && (
                                        <Thead>
                                            <Tr>
                                                <Th style={{width:'30%'}}>Title</Th>
                                                <Th style={{width:'20%'}}>Minutes</Th>
                                                <Th style={{width:'50%'}}>Rate</Th>
                                            </Tr>
                                        </Thead>
                                        )}
                                        
                                        <Tbody>
                                            <Tr>
                                                <Td  style={{width:'30%'}}>{catTitle}</Td>
                                                <Td  style={{width:'20%'}}>{duration} Min</Td>
                                                <Td  style={{width:'50%'}}>
                                                    <input
                                                        type="number"
                                                        name={`spa_price_${serviceIndex}_${categoryIndex}_${packageIndex}`}
                                                        value={spaFormData[serviceIndex]?.categories[categoryIndex]?.packages[packageIndex]?.price}
                                                        onChange={(e) => spaHandleInputChange(e, serviceIndex, categoryIndex, packageIndex)}
                                                    />
                                                </Td>
                                            </Tr>
                                        </Tbody>
                                    </>
                                );
                            })}
                            </Table>
                            </TableContainer>
                        ))}
                        </GridItem>
                    ))
                    }
                    <div></div>
                    <div></div>
                    <button className={styles.adminBtn} style={{padding:"20px"}} onClick={handleSubmitSpa}>Submit Spa Data</button>
                </>
            )}
            


        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  )
}

export default EditTierScreen