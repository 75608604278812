import {
  Box,
  Heading,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import React from "react";
import styles from "../styles/Dashboard.module.css";
import { TbSofa } from "react-icons/tb";
import { BsBarChartLine, BsChatDots, BsCollection } from "react-icons/bs";
import { Link } from "react-router-dom";
import AdminNavbar from "../components/AdminNavbar";


const HomeScreen = () => {




  return (
    <>
    <AdminNavbar>
      <Box as="section">
        <SimpleGrid
          columns={{ base: "1", md: "2", xl: "4" }}
          spacing="5"
          className={styles.cardBox}
        >
          <Box className={styles.card}>
            <Box>
              <Box className={styles.numbers}>20</Box>
              <Box className={styles.cardName}>Total Products</Box>
            </Box>
            <Box className={styles.iconBx}>
              <TbSofa />
            </Box>
          </Box>
          <Box className={styles.card}>
            <Box>
              <Box className={styles.numbers}>30</Box>
              <Box className={styles.cardName}>Sales</Box>
            </Box>
            <Box className={styles.iconBx}>
              <BsBarChartLine />
            </Box>
          </Box>
          <Box className={styles.card}>
            <Box>
              <Box className={styles.numbers}>
                55
              </Box>
              <Box className={styles.cardName}>Enquiry</Box>
            </Box>
            <Box className={styles.iconBx}>
              <BsChatDots />
            </Box>
          </Box>
          <Box className={styles.card}>
            <Box>
              <Box className={styles.numbers}>
                100
              </Box>
              <Box className={styles.cardName}>Collections</Box>
            </Box>
            <Box className={styles.iconBx}>
              <BsCollection />
            </Box>
          </Box>
        </SimpleGrid>
      </Box>
      {/* Card Section End */}

      {/* Data List Section Start */}

      <Box as="section">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 3, xl: 2 }}
            className={styles.recentOrders}
          >
            <Box className={styles.cardHeader}>
              <Heading>Recent Orders</Heading>
              <Link to={"/admin/orders"}>
                <a className={styles.adminBtn}>View All</a>
              </Link>
            </Box>
            <TableContainer className={styles.tableContainer}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Price</Th>
                    <Th>Payment</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  
                        <Tr >
                          <Td>Salman</Td>
                          <Td>89898</Td>
                          <Td>Cash</Td>
                          <Td>
                            <span className={styles.inProgress}>
                              inProgress
                            </span>
                          </Td>
                        </Tr>
                      
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Price</Th>
                    <Th>Payment</Th>
                    <Th>Status</Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </GridItem>
          <GridItem
            colSpan={{ base: 3, xl: 1 }}
            className={styles.recentCustomers}
          >
            <Box className={styles.cardHeader}>
              <Heading>Recent Enquiry</Heading>
            </Box>
            <TableContainer className={styles.tableContainer}>
              <Table variant="simple">
                <Tbody>
                      <Tr >
                        <Td width={"60px"}>
                          <Box className={styles.imgBx}>
                            <img
                              src={`/assets/images/img1 (1).jpg`}
                            />
                          </Box>
                        </Td>
                        <Td>
                          <Heading as={"h4"}>
                            McCollinsmedia
                            <br />
                            <span>Email: McCollinsmedia@gmail.com</span>
                            <br />
                            <span>Phone: 546618488</span>
                          </Heading>
                        </Td>
                      </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </>
  );
};


export default HomeScreen;
