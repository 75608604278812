import { ADMIN_SIGNIN_FAIL, ADMIN_SIGNIN_REQUEST, ADMIN_SIGNIN_SUCCESS, ADMIN_SIGNOUT, GET_FORM_FAIL, GET_FORM_REQUEST, GET_FORM_SUCCESS } from "../constants/Admin";

export const adminSigninReducer = (state = {}, action) => {
    switch (action.type) {
      case ADMIN_SIGNIN_REQUEST:
        return { loading: true };
      case ADMIN_SIGNIN_SUCCESS:
        return { loading: false, adminInfo: action.payload };
      case ADMIN_SIGNIN_FAIL:
        return { loading: false, error: action.payload };
      case ADMIN_SIGNOUT:
        return {};
      default:
        return state;
    }
  };


  export const getFormsReducer =(state={loading:true,forms:[]},action)=>{
    switch(action.type){
      case GET_FORM_REQUEST:
        return {loading:true}
      case GET_FORM_SUCCESS:
        return {loading:false,forms:action.payload.data}
      case GET_FORM_FAIL:
        return {loading:false, error:action.payload}
      default:
        return state;      
    }
  }