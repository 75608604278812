import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export const showSuccessNotification = (message) => {
    
    Swal.fire({
      title: message,
      text: 'Thanks',
      type: 'success',
      icon: 'success',
    });
    // navigate('/spa');
  };