import { Box, FormControl, FormLabel, Grid, GridItem, Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { addBanner } from "../actions/BannerAction";
import AdminNavbar from "../components/AdminNavbar";
import styles from "../styles/Dashboard.module.css";

function AddBanners() {

  const [avatar, setAvatar] = useState('')

  const [previewSource, setPreviewSource] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [page, setPage] = useState('')
  // const location = useLocation()
  // const {page}=location.state
  console.log(page);
  const createBanner=useSelector(state=>state.createBanner)
  const {success}=createBanner
  const navigate=useNavigate()

  const dispatch=useDispatch()

  
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    setSelectedFile(file);
    // setFileInputState(e.target.value);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setAvatar(reader.result);
  };
};

const previewFile = (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
      setPreviewSource(reader.result);
  };
};

const handleSubmit=(e)=>{
    e.preventDefault()
    if(page === ''){
        alert('please select page')
    }else{
        dispatch(addBanner(page,avatar))
    }

  
}

// console.log(page);
useEffect(()=>{
  if(success){ 
    Swal.fire({
      title: 'banner created Succesfully.',
      text: "Thanks",
      type: 'success',    
      icon: 'success',        
    }); 
    navigate('/banner')
  }

},[success])


  return (
    <div >
      <AdminNavbar>
      <Box as="section" className="addBanner">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <FormControl isRequired>
              <FormLabel>Select  Images for Mobile Banner</FormLabel>
              <Select placeholder="Select option" onChange={(e)=>setPage(e.target.value)}>
                <option value="home Mobile-banner">Home</option>
                <option value="contact Mobile-banner">Contact Us</option>
                <option value="faq Mobile-banner">FAQ</option>
                <option value="about Mobile-banner">About Us</option>
                <option value="review Mobile-banner">Guest Reviews</option>
                <option value="gallery Mobile-banner">Gallery</option>
                <option value="News & Events Mobile-banner">News & Events</option>
                <option value="accommodation Mobile-banner">Accommodation</option>
                <option value="Meeting & Events Mobile-banner">Meet & Greet</option>
                <option value="lounges Mobile-banner">Lounges</option>
                <option value="Food & Beverage Mobile-banner">Food & Beverage</option>
                <option value="Spa & Wellness Mobile-banner">Spa & Wellness</option>
                <option value="Site Map Mobile-banner">Site Map</option>
              </Select>
  
            </FormControl>
            <div className="add-banners">
              <div className="write">
                {previewSource && (
                  <img
                    className="writeImg"
                    src={previewSource}
                    alt="chosen"
                    // style={{ height: '300px' }}
                  />
                )}
                <form className="writeForm" onSubmit={handleSubmit}>
                  <div className="writeFormGroup">
                    <label htmlFor="fileInput">
                      <i
                        style={{ marginTop: "24px" }}
                        className="writeIcon fas fa-plus"
                      ></i>
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                    />
                  </div>
                  {previewSource && (
                    <button style={{marginBottom:'40px'}} className="writeSubmit" type="submit">
                      Publish
                    </button>
                  )}
                </form>
              </div>
            </div>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  );
}

export default AddBanners;
