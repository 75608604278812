import {
  AHLAN_CREATE_FAIL,
  AHLAN_CREATE_REQUEST,
  AHLAN_CREATE_RESET,
  AHLAN_CREATE_SUCCESS,
  DELETE_AHLAN_FAIL,
  DELETE_AHLAN_REQUEST,
  DELETE_AHLAN_RESET,
  DELETE_AHLAN_SUCCESS,
  EDIT_AHLAN_FAIL,
  EDIT_AHLAN_REQUEST,
  EDIT_AHLAN_RESET,
  EDIT_AHLAN_SUCCESS,
  FETCH_AHLAN_DETAILS_FAIL,
  FETCH_AHLAN_DETAILS_REQUEST,
  FETCH_AHLAN_DETAILS_RESET,
  FETCH_AHLAN_DETAILS_SUCCESS,
  FETCH_AHLAN_FAIL,
  FETCH_AHLAN_REQUEST,
  FETCH_AHLAN_SUCCESS,
} from "../constants/Ahlan";

export const createAhlanReducer = (state = {}, action) => {
  switch (action.type) {
    case AHLAN_CREATE_REQUEST:
      return { loading: true };
    case AHLAN_CREATE_SUCCESS:
      return { loading: false, success: true, response: action.payload };
    case AHLAN_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case AHLAN_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const getAhlansReducer = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case FETCH_AHLAN_REQUEST:
      return { loading: true };
    case FETCH_AHLAN_SUCCESS:
      return { loading: false, ahlans: action.payload.data };
    case FETCH_AHLAN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteAhlanReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_AHLAN_REQUEST:
      return { loading: true };
    case DELETE_AHLAN_SUCCESS:
      return { loading: false, success: true, response: action.payload };
    case DELETE_AHLAN_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_AHLAN_RESET:
      return {};
    default:
      return state;
  }
};


export const editAhlanReducer = (
  state = { loading: true, success: false },
  action
) => {
  switch (action.type) {
    case EDIT_AHLAN_REQUEST:
      return {  loading: true };
    case EDIT_AHLAN_SUCCESS:
      return { loading: false, success: true };
    case EDIT_AHLAN_FAIL:
      return { loading: false, error: action.payload };
      case EDIT_AHLAN_RESET:
        return {}

    default:
      return state;
  }
};


export const fetchAhlanReducer = (
  state = { loading: true, ahlans: [] },
  action
) => {
  switch (action.type) {
    case FETCH_AHLAN_DETAILS_REQUEST:
      return { loading: true };
    case FETCH_AHLAN_DETAILS_SUCCESS:
      return { loading: false, ahlans: action.payload ,data:true};
    case FETCH_AHLAN_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case FETCH_AHLAN_DETAILS_RESET:
      return {}
    default:
      return state;
  }
};