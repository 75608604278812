import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { createAhlanLounge, editAhlanLoungeAction, getAhlanLoungeById } from '../actions/AhlanLoungeAction';
import AdminNavbar from '../components/AdminNavbar';
import LoadingBox from '../components/LoadingBox';
import { getAhlanById } from '../actions/AhlanAction';
import { showSuccessNotification } from '../components/PopupBox';
import { AHLANLOUNGE_CREATE_RESET, EDIT_AHLANLOUNGE_RESET } from '../constants/AhlanLounge';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import { Button } from '@chakra-ui/react';
// import htmlToDraft from 'html-to-draftjs';


function AddAhlanLounge() {
// add ahlan
const { id } = useParams();
const [initialEditorState, setInitialEditorState] = useState(EditorState.createEmpty());
    const [title, setTitle] = useState('')
    const [datas, setDatas] = useState('')
    const [note, setNote] = useState('')
    const [avatar, setAvatar] = useState('')
    const [price, setPrice] = useState({
      adult:"",
      extra:"",
      children:"",
      group:"",
    })
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [rawContentState, setRawContentState] = useState({});
    const [htmlContent, setHtmlContent] = useState('');
    const getAhlans=useSelector(state=>state.fetchAhlanLounge)
    const {ahlans,data}=getAhlans
    const [serviceType, setServiceType] = useState("")
    const [terminalType, setTerminalType] = useState("")
    const [previewSource, setPreviewSource] = useState('');
    const dispatch=useDispatch()

    const editAhlanLounge=useSelector((state)=>state.editAhlanLounge)
    const {success:successEdit}=editAhlanLounge

    const createAhlanLoungeState=useSelector(state=>state.createAhlanLoungeState)
    const {success,loading}=createAhlanLoungeState
    const navigate=useNavigate()

    
    const [apiLoading, setApiLoading] = useState(false)


    const handleFileInputChange = (e) => {
      const file = e.target.files[0];
      previewFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setAvatar(reader.result);
    };
      
  };

  const handleChange = (e, key) => {
    const { value } = e.target;
    setPrice((prevPrice) => ({
      ...prevPrice,
      [key]: value,
    }));
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
        setPreviewSource(reader.result);
    };
};




const handleSubmit=async(e)=>{
  e.preventDefault()
  setApiLoading(true)
  if(!id){
  if(!title || !avatar || !price || !terminalType || !htmlContent   ){
    console.log('no title');
    Swal.fire({
      title: 'Please fill all the field.',
      text: "Thanks",
      type: 'success',    
      icon: 'error',        
    });
    setApiLoading(false)
  }else{
    console.log('no title');
    dispatch(createAhlanLounge(title,avatar,serviceType,terminalType,htmlContent,price.adult,price.extra,price.group,price.children,note))
        .then(() => {
          Swal.fire({
            title: 'Ahlan Lounge Added Successfully',
            text: "Thanks",
            type: 'success',
            icon: 'success',
          });
          navigate('/ahlan-lounge');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setApiLoading(false);
        });

  }
}else{
  const data={
    lounge_id:id,
    title,
    service:serviceType,
    terminal:terminalType,
    description:htmlContent,
    adult: price.adult,
    extra:price.extra,
    children:price.children,
    group:price.group,
    note:note
  }
  if(avatar){
    data.avatar=avatar
  }
  dispatch(editAhlanLoungeAction(data))
    .then(() => {
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setApiLoading(false);
    });
}
}


useEffect(() => {
  if (success) {
    showSuccessNotification('AhlanLounge created Successfully.');
    dispatch({ type: AHLANLOUNGE_CREATE_RESET });
    navigate('/ahlan-lounge');
  }
  if (successEdit) {
    showSuccessNotification('AhlanLounge Edited Successfully.');
    dispatch({ type: EDIT_AHLANLOUNGE_RESET });
    navigate('/ahlan-lounge');
  }
}, [success, successEdit]);


useEffect(()=>{
 
  if(id){
    dispatch(getAhlanLoungeById(id)) 
    
      console.log('sap22')


  
  }
},[id])

useEffect(() => {
  if(data){
    setTitle(ahlans?.data?.title)
    // setCategory(ahlans?.data?.category)
    // setDatas(ahlans?.data?.description)
    setServiceType(ahlans?.data?.service)
    setTerminalType(ahlans?.data?.terminal)
    setDatas(ahlans?.data?.description)
    setNote(ahlans?.data?.note)
    setPrice((prevState) => ({
      ...prevState,
      adult: ahlans?.data?.adult,
      extra: ahlans?.data?.extra,
      children: ahlans?.data?.children,
      group: ahlans?.data?.group,
    }));
    setHtmlContent(ahlans?.data?.description)
    const htmlContents = ahlans?.data?.description;
  
    
    const htmlContent = ahlans?.data?.description;

    // Convert the HTML content to Draft.js ContentState
    const blocksFromHTML = convertFromHTML(htmlContent);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    // Set the initial EditorState with the ContentState
    setEditorState(EditorState.createWithContent(contentState));
    setPreviewSource(ahlans?.data?.image)
  }

 
}, [data])

console.log(htmlContent,'htmlContent');

const onEditorStateChange = (newEditorState) => {
  setEditorState(newEditorState);
  const content = newEditorState.getCurrentContent();
  setRawContentState(convertToRaw(content));
  const html = draftToHtml(convertToRaw(content));
  setHtmlContent(html);
};

console.log(rawContentState,'rawContentState')
console.log(htmlContent,'htmlContent')

  return (
    <div>
      <AdminNavbar>
        <div className="write">
          {previewSource && (
            <img
              className="writeImg"
              src={previewSource}
              alt="chosen"
              // style={{ height: '300px' }}
            />
          )}
          <form className="writeForm" onSubmit={handleSubmit}>
            <div className="writeFormGroup">
              <label htmlFor="fileInput">
                <i className="writeIcon fas fa-plus"></i>
              </label>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
              <input
                className="writeInput"
                placeholder="Title"
                type="text"
                value={title}
                autoFocus={true}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            {/* <div className="writeFormGroup">
          <textarea
            className="writeInput writeText"
            placeholder="Tell your story..."
            type="text"
            value={datas}
            autoFocus={true}
            onChange={(e) => setDatas(e.target.value)}
          />

        </div> */}
            <div>
              <h2>Live Preview:</h2>
              <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </div>
            <div className="blog-editor">
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                mention={{
                  separator: " ",
                  trigger: "@",
                  suggestions: [
                    { text: "APPLE", value: "apple" },
                    { text: "BANANA", value: "banana", url: "banana" },
                    { text: "CHERRY", value: "cherry", url: "cherry" },
                    { text: "DURIAN", value: "durian", url: "durian" },
                    { text: "EGGFRUIT", value: "eggfruit", url: "eggfruit" },
                    { text: "FIG", value: "fig", url: "fig" },
                    {
                      text: "GRAPEFRUIT",
                      value: "grapefruit",
                      url: "grapefruit",
                    },
                    { text: "HONEYDEW", value: "honeydew", url: "honeydew" },
                  ],
                }}
              />
            </div>
            <div className="writeFormGroup">
              <select
                onChange={(e) => {
                  setServiceType(e.target.value);
                }}
                className="writeInput"
                style={{ width: "500px" }}
                required
              >
                <option value={serviceType ? serviceType : ""}>
                  {serviceType ? serviceType : "Select Servie Type"}
                </option>
                <option value="Arrival">Arrival</option>
                <option value="Departure">Departure</option>
                <option value="Transit">Transit</option>
              </select>
            </div>

            <div className="writeFormGroup" style={{display:"flex",flexDirection:"column",alignItems:"start"}}>
              <label style={{padding:"20px"}}>Select the Terminal <span>Hold Ctrl for Multi Select</span></label>
              <select
                onChange={(e) => {
                  const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
                  setTerminalType(selectedOptions);
                }}
                className="writeInput"
                style={{ width: "500px",overflow:"auto" }}
                required
                multiple
                value={terminalType} // Ensure that the selected values are controlled by the state
              >
                {/* Use a conditional rendering for the default option */}
                <option value="" disabled hidden>
                  {terminalType.length ? terminalType.join(', ') : 'Select terminal(s)'}
                </option>
                <option value="Terminal 1">Terminal 1</option>
                <option value="Terminal 2">Terminal 2</option>
                <option value="Terminal 3">Terminal 3</option>
              </select>
            </div>

            <div
              className="writeFormGroup"
              style={{ display: "block", marginTop: " " }}
            >
              <input
                type="text"
                class="writeInput"
                placeholder="Adult"
                value={price.adult}
                onChange={(e) => handleChange(e, "adult")}
              />
              <br />

              <input
                type="text"
                placeholder="Extra"
                class="writeInput"
                value={price.extra}
                onChange={(e) => handleChange(e, "extra")}
              />
              <br />

              <input
                type="text"
                class="writeInput"
                placeholder="Children"
                value={price.children}
                onChange={(e) => handleChange(e, "children")}
              />
              <br />

              <input
                type="text"
                placeholder="Group"
                class="writeInput"
                value={price.group}
                onChange={(e) => handleChange(e, "group")}
              />

                  <br />

                  <input
                    type="text"
                    placeholder='Add Special Instruction here Seperated By Comma'
                    class="writeInput"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
            </div>
            {/* <button className="writeSubmit" type="submit">
              Publish
            </button> */}
            <Button className="writeSubmit" style={{position:"absolute",background:"teal"}} type="submit" isLoading={apiLoading}
              loadingText="Submitting" >Publish
              </Button>
            {loading && <LoadingBox></LoadingBox>}
          </form>
        </div>
      </AdminNavbar>
    </div>
  );
}

export default AddAhlanLounge;