import {
  TIER_CREATE_FAIL,
  TIER_CREATE_REQUEST,
  TIER_CREATE_RESET,
  TIER_CREATE_SUCCESS,
  DELETE_TIER_FAIL,
  DELETE_TIER_REQUEST,
  DELETE_TIER_RESET,
  DELETE_TIER_SUCCESS,
  FETCH_TIER_FAIL,
  FETCH_TIER_REQUEST,
  FETCH_TIER_SUCCESS,
} from "../constants/Tier";

export const createTierReducer = (state = {}, action) => {
  switch (action.type) {
    case TIER_CREATE_REQUEST:
      return { loading: true };
    case TIER_CREATE_SUCCESS:
      return { loading: false, success: true, response: action.payload };
    case TIER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case TIER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const getTierReducer = (
  state = { loading: true, tierState: [] },
  action
) => {
  switch (action.type) {
    case FETCH_TIER_REQUEST:
      return { loading: true };
    case FETCH_TIER_SUCCESS:
      return { loading: false, tierState: action.payload.data };
    case FETCH_TIER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteTierReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_TIER_REQUEST:
      return { loading: true };
    case DELETE_TIER_SUCCESS:
      return { loading: false, success: true, response: action.payload };
    case DELETE_TIER_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_TIER_RESET:
      return {};
    default:
      return state;
  }
};
