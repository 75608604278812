export const SLIDER_CREATE_REQUEST ='SLIDER_CREATE_REQUEST'
export const SLIDER_CREATE_SUCCESS ='SLIDER_CREATE_SUCCESS'
export const SLIDER_CREATE_FAIL ='SLIDER_CREATE_FAIL'
export const SLIDER_CREATE_RESET ='SLIDER_CREATE_RESET'


export const FETCH_SLIDER_REQUEST ='FETCH_SLIDER_REQUEST'
export const FETCH_SLIDER_SUCCESS ='FETCH_SLIDER_SUCCESS'
export const FETCH_SLIDER_FAIL ='FETCH_SLIDER_FAIL'

export const DELETE_SLIDER_REQUEST ='DELETE_SLIDER_REQUEST'
export const DELETE_SLIDER_SUCCESS ='DELETE_SLIDER_SUCCESS'
export const DELETE_SLIDER_FAIL ='DELETE_SLIDER_FAIL'
export const DELETE_SLIDER_RESET='DELETE_SLIDER_RESET'
