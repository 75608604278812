import axios from "axios";
import {
  AHLANLOUNGE_CREATE_FAIL,
  AHLANLOUNGE_CREATE_REQUEST,
  AHLANLOUNGE_CREATE_SUCCESS,
  DELETE_AHLANLOUNGE_FAIL,
  DELETE_AHLANLOUNGE_REQUEST,
  DELETE_AHLANLOUNGE_SUCCESS,
  EDIT_AHLANLOUNGE_FAIL,
  EDIT_AHLANLOUNGE_REQUEST,
  EDIT_AHLANLOUNGE_SUCCESS,
  FETCH_AHLANLOUNGE_DETAILS_FAIL,
  FETCH_AHLANLOUNGE_DETAILS_REQUEST,
  FETCH_AHLANLOUNGE_DETAILS_SUCCESS,
  FETCH_AHLANLOUNGE_FAIL,
  FETCH_AHLANLOUNGE_REQUEST,
  FETCH_AHLANLOUNGE_SUCCESS,
  Url,
} from "../constants/AhlanLounge";

export const createAhlanLounge =
  (
    title,
    image,
    service,
    terminal,
    description,
    adult,
    extra,
    group,
    children,
    note
  ) =>
  async (dispatch) => {
    dispatch({ type: AHLANLOUNGE_CREATE_REQUEST });
    try {
      const { data } = await axios.post(
        `${Url}/api/v1/ahlanLounge`,
        {
          title,
          image,
          service,
          terminal,
          description,
          adult,
          extra,
          group,
          children,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log(data, "dataaaaaaaaaa");
      dispatch({ type: AHLANLOUNGE_CREATE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: AHLANLOUNGE_CREATE_FAIL, payload: message });
    }
  };

export const getAhlanLounge = () => async (dispatch) => {
  dispatch({ type: FETCH_AHLANLOUNGE_REQUEST });
  try {
    const { data } = await axios.get(`${Url}/api/v1/ahlanLounge`);
    dispatch({ type: FETCH_AHLANLOUNGE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: FETCH_AHLANLOUNGE_FAIL, payload: message });
  }
};

export const deleteAhlanLounge = (id) => async (dispatch) => {
  dispatch({ type: DELETE_AHLANLOUNGE_REQUEST });
  try {
    const { data } = await axios.delete(`${Url}/api/v1/ahlanLounge/${id}`);
    dispatch({ type: DELETE_AHLANLOUNGE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: DELETE_AHLANLOUNGE_FAIL, payload: message });
  }
};

export const getAhlanLoungeById = (id) => async (dispatch) => {
  dispatch({ type: FETCH_AHLANLOUNGE_DETAILS_REQUEST });
  try {
    const { data } = await axios.get(`${Url}/api/v1/ahlanLounge/${id}`);
    dispatch({ type: FETCH_AHLANLOUNGE_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: FETCH_AHLANLOUNGE_DETAILS_FAIL, payload: message });
  }
};

export const editAhlanLoungeAction = (ahlan) => async (dispatch) => {
  dispatch({ type: EDIT_AHLANLOUNGE_REQUEST });

  try {
    const { data } = await axios.put(`${Url}/api/v1/ahlanLounge`, ahlan);
    dispatch({ type: EDIT_AHLANLOUNGE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: EDIT_AHLANLOUNGE_FAIL, payload: message });
  }
};
