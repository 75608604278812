export const AHLANLOUNGE_CREATE_REQUEST = "AHLANLOUNGE_CREATE_REQUEST";
export const AHLANLOUNGE_CREATE_SUCCESS = "AHLANLOUNGE_CREATE_SUCCESS";
export const AHLANLOUNGE_CREATE_FAIL = "AHLANLOUNGE_CREATE_FAIL";
export const AHLANLOUNGE_CREATE_RESET = "AHLANLOUNGE_CREATE_RESET";

export const FETCH_AHLANLOUNGE_REQUEST = "FETCH_AHLANLOUNGE_REQUEST";
export const FETCH_AHLANLOUNGE_SUCCESS = "FETCH_AHLANLOUNGE_SUCCESS";
export const FETCH_AHLANLOUNGE_FAIL = "FETCH_AHLANLOUNGE_FAIL";

export const DELETE_AHLANLOUNGE_REQUEST = "DELETE_AHLANLOUNGE_REQUEST";
export const DELETE_AHLANLOUNGE_SUCCESS = "DELETE_AHLANLOUNGE_SUCCESS";
export const DELETE_AHLANLOUNGE_FAIL = "DELETE_AHLANLOUNGE_FAIL";
export const DELETE_AHLANLOUNGE_RESET = "DELETE_AHLANLOUNGE_RESET";

export const EDIT_AHLANLOUNGE_REQUEST = "EDIT_AHLANLOUNGE_REQUEST";
export const EDIT_AHLANLOUNGE_SUCCESS = "EDIT_AHLANLOUNGE_SUCCESS";
export const EDIT_AHLANLOUNGE_FAIL = "EDIT_AHLANLOUNGE_FAIL";
export const EDIT_AHLANLOUNGE_RESET = "EDIT_AHLANLOUNGE_RESET";


export const FETCH_AHLANLOUNGE_DETAILS_REQUEST = "FETCH_AHLANLOUNGE_DETAILS_REQUEST";
export const FETCH_AHLANLOUNGE_DETAILS_SUCCESS = "FETCH_AHLANLOUNGE_DETAILS_SUCCESS";
export const FETCH_AHLANLOUNGE_DETAILS_FAIL = "FETCH_AHLANLOUNGE_DETAILS_FAIL";
export const FETCH_AHLANLOUNGE_DETAILS_RESET = 'FETCH_AHLANLOUNGE_DETAILS_RESET'

export const Url = "https://dih-node-vercel-production-3413.up.railway.app";
