import React, { useEffect, useState } from "react";
import { Box, GridItem, Grid, Select } from "@chakra-ui/react";
import styles from "../styles/Dashboard.module.css";
import {
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
import { Textarea } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addFaq, editFaqAction, getFaq, getFaqById } from "../actions/FaqAction";
import Swal from "sweetalert2";
import AdminNavbar from "../components/AdminNavbar";
import LoadingBox from "../components/LoadingBox";
import { EDIT_FAQ_RESET, FAQ_CREATE_RESET } from "../constants/Faq";
import { showSuccessNotification } from "../components/PopupBox";

function AddFaq() {
    const [question, setQestion] = useState('')
    const [answer, setAnswer] = useState('')
    const [section, setSection] = useState('')
    const dispatch=useDispatch()
    const createFaq=useSelector(state=>state.createFaq)
    const {success,loading}=createFaq
    const getFaqs=useSelector(state=>state.fetchFaq)
    const {faqs,data}=getFaqs
    const editFaq =useSelector(state=>state.editFaq)
    const {success:successEdit}=editFaq
    const { id } = useParams();

    const navigate=useNavigate()

    const submitHandler=(e)=>{
      e.preventDefault()
      if(!id){
        dispatch(addFaq(question,answer,section))

      }else{
        const data={
          faq_id: id,
          question,
          answer,
          section
        }
        dispatch(editFaqAction(data))
      }

    }

    

  useEffect(() => {
    if (success) {
      showSuccessNotification('FAQ created Successfully.');
      dispatch({ type: FAQ_CREATE_RESET });
      navigate('/faq');
    }
    if (successEdit) {
      showSuccessNotification('FAQ Edited Successfully.');
      dispatch({ type: EDIT_FAQ_RESET });
      navigate('/faq');
    }
  }, [success, successEdit]);
    // console.log(title);

    useEffect(()=>{
     
      if(id ){
        dispatch(getFaqById(id))
       
      }
    },[id,dispatch])


    useEffect(()=>{
      if(data){
        
          setQestion(faqs.data.question)
          setAnswer(faqs.data.answer)
          setSection(faqs.data.section)
          console.log('hey')
        }
      
    },[data])
  return (
    <div>
      <AdminNavbar>
      <Box as="section"  className="faq">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <form onSubmit={submitHandler}>
            <FormControl isRequired className="faq-form">
              <FormLabel>Question</FormLabel>
              <Input placeholder="Question" value={question} onChange={(e)=>setQestion(e.target.value)} required/>
              <FormLabel>Answer</FormLabel>
              <Textarea  placeholder="Answer" value={answer} onChange={(e)=>setAnswer(e.target.value)} required/>
              <FormLabel>Section</FormLabel>
              <Select required value={section} onChange={(e)=>setSection(e.target.value)}>
                <option>Choose the Section</option>
                <option value="Rooms">Rooms</option>
                <option value="Ahlan">Ahlan</option>
                <option value="Spa">Timeless Spa</option>
                <option value="Restaurants&Bars">Restaurants & Bars</option>
              </Select>
              <button type="submit">submit</button>
              {loading && <LoadingBox></LoadingBox>}
            </FormControl>
            </form>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  );
}

export default AddFaq;
