import axios from "axios";
import {
  TIER_CREATE_FAIL,
  TIER_CREATE_REQUEST,
  TIER_CREATE_SUCCESS,
  DELETE_TIER_FAIL,
  DELETE_TIER_REQUEST,
  DELETE_TIER_SUCCESS,
  FETCH_TIER_FAIL,
  FETCH_TIER_REQUEST,
  FETCH_TIER_SUCCESS,
  Url,
} from "../constants/Tier";

export const createTier =
  (title, image, service, description, adult, extra, group, children) =>
  async (dispatch) => {
    dispatch({ type: TIER_CREATE_REQUEST });
    try {
      const { data } = await axios.post(
        `${Url}/api/v1/tier`,
        {
          title,
          image,
          service,
          description,
          adult,
          extra,
          group,
          children,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log(data, "dataaaaaaaaaa");
      dispatch({ type: TIER_CREATE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: TIER_CREATE_FAIL, payload: message });
    }
  };

export const getTier = () => async (dispatch) => {
  dispatch({ type: FETCH_TIER_REQUEST });
  try {
    const { data } = await axios.get(`${Url}/api/v1/tier`);
    dispatch({ type: FETCH_TIER_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: FETCH_TIER_FAIL, payload: message });
  }
};

export const deleteTier = (id) => async (dispatch) => {
  dispatch({ type: DELETE_TIER_REQUEST });
  try {
    const { data } = await axios.delete(`${Url}/api/v1/tier/${id}`);
    dispatch({ type: DELETE_TIER_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: DELETE_TIER_FAIL, payload: message });
  }
};
