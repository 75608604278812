

import { Box, Grid, GridItem, Input, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { addSlider } from "../actions/SliderAction";
import AdminNavbar from "../components/AdminNavbar";
import LoadingBox from "../components/LoadingBox";
import styles from "../styles/Dashboard.module.css";

function AddHomeSlider() {
  const createSlider=useSelector(state=>state.createSlider)
  const {success,loading}=createSlider
  const [avatar, setAvatar] = useState('')
 
  const [previewSource, setPreviewSource] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const location = useLocation()
  console.log(location.state);
  const navigate=useNavigate()


  const dispatch = useDispatch();

  
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    setSelectedFile(file);
    // setFileInputState(e.target.value);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setAvatar(reader.result);
  };
};

const previewFile = (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
      setPreviewSource(reader.result);
  };
};

const handleSubmit=(e)=>{
  e.preventDefault()
  const page='home'
  dispatch(addSlider(page,avatar))

}
useEffect(()=>{
  if(success){ 
    Swal.fire({
      title: 'Slider Added Succesfully.',
      text: "Thanks",
      type: 'success',    
      icon: 'success',        
    }); 
    navigate('/homeslider')
  }

},[success])
  return (
    <div className="">
      <AdminNavbar>
      <Box as="section">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
       
            <div className="addSlider">
              <div className="write">
                {previewSource && (
                  <img
                    className="writeImg"
                    src={previewSource}
                    alt="chosen"
                    // style={{ height: '300px' }}
                  />
                )}
                <form className="writeForm" onSubmit={handleSubmit}>
                  <div className="writeFormGroup">
                    <label htmlFor="fileInput">
                      <i
                        style={{ marginTop: "24px" }}
                        className="writeIcon fas fa-plus"
                      ></i>
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      // onChange={(e) => setFile(e.target.files[0])}
                      onChange={handleFileInputChange}
                    />
                  </div>
                  {previewSource && (
                    <button className="writeSubmit" type="submit">
                      Publish
                    </button>
                  )}
                  {loading && <LoadingBox></LoadingBox>}
                </form>
              </div>
            </div>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  );
}

export default AddHomeSlider;
