import { REVIEW_CREATE_FAIL, REVIEW_CREATE_REQUEST, REVIEW_CREATE_RESET, REVIEW_CREATE_SUCCESS, DELETE_REVIEW_FAIL, DELETE_REVIEW_REQUEST, DELETE_REVIEW_RESET, DELETE_REVIEW_SUCCESS, FETCH_REVIEW_FAIL, FETCH_REVIEW_REQUEST, FETCH_REVIEW_SUCCESS, FETCH_REVIEW_DETAILS_REQUEST, FETCH_REVIEW_DETAILS_SUCCESS, FETCH_REVIEW_DETAILS_FAIL, EDIT_REVIEW_RESET, EDIT_REVIEW_FAIL, EDIT_REVIEW_REQUEST, EDIT_REVIEW_SUCCESS, FETCH_REVIEW_DETAILS_RESET } from "../constants/Review";

export const createReviewReducer= (state= {}, action)=>{
    switch(action.type){
      case REVIEW_CREATE_REQUEST:
        return {loading:true};
      case REVIEW_CREATE_SUCCESS:
        return {loading:false,success:true, response:action.payload};
      case REVIEW_CREATE_FAIL:
        return {loading:false,error:action.payload}
      case REVIEW_CREATE_RESET:
        return {};
      default:
        return state;        
    }
  }


  export const getReviewsReducer =(state={loading:true,reviews:[]},action)=>{
    switch(action.type){
      case FETCH_REVIEW_REQUEST:
        return {loading:true}
      case FETCH_REVIEW_SUCCESS:
        return {loading:false,reviews:action.payload.data}
      case FETCH_REVIEW_FAIL:
        return {loading:false, error:action.payload}
      default:
        return state;      
    }
  }

  export const deleteReviewReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_REVIEW_REQUEST:
        return { loading: true };
      case DELETE_REVIEW_SUCCESS:
        return { loading: false, success: true ,response:action.payload};
      case DELETE_REVIEW_FAIL:
        return { loading: false, error: action.payload };
      case DELETE_REVIEW_RESET:
        return {};
      default:
        return state;
    }
  };


  export const fetchReviewReducer = (
    state = { loading: true, reviews: [] },
    action
  ) => {
    switch (action.type) {
      case FETCH_REVIEW_DETAILS_REQUEST:
        return {  loading: true };
      case FETCH_REVIEW_DETAILS_SUCCESS:
        return { loading: false, reviews: action.payload ,data:true};
      case FETCH_REVIEW_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      case FETCH_REVIEW_DETAILS_RESET:
        return {}
      default:
        return state;
    }
  }


  export const editReviewReducer = (
    state = { loading: true, success: false },
    action
  ) => {
    switch (action.type) {
      case EDIT_REVIEW_REQUEST:
        return { ...state, loading: true };
      case EDIT_REVIEW_SUCCESS:
        return { loading: false, success: true };
      case EDIT_REVIEW_FAIL:
        return { loading: false, error: action.payload };
      case EDIT_REVIEW_RESET:
        return {}  
      default:
        return state;
    }
  };