import { GALLERY_CREATE_FAIL, GALLERY_CREATE_REQUEST, GALLERY_CREATE_RESET, GALLERY_CREATE_SUCCESS, DELETE_GALLERY_FAIL, DELETE_GALLERY_REQUEST, DELETE_GALLERY_RESET, DELETE_GALLERY_SUCCESS, FETCH_GALLERY_FAIL, FETCH_GALLERY_REQUEST, FETCH_GALLERY_SUCCESS } from "../constants/Gallery";

export const createGalleryReducer= (state= {}, action)=>{
    switch(action.type){
      case GALLERY_CREATE_REQUEST:
        return {loading:true};
      case GALLERY_CREATE_SUCCESS:
        return {loading:false,success:true, response:action.payload};
      case GALLERY_CREATE_FAIL:
        return {loading:false,error:action.payload}
      case GALLERY_CREATE_RESET:
        return {};
      default:
        return state;        
    }
  }


  export const getGalleryReducer =(state={loading:true,gallery:[]},action)=>{
    switch(action.type){
      case FETCH_GALLERY_REQUEST:
        return {loading:true}
      case FETCH_GALLERY_SUCCESS:
        return {loading:false,gallery:action.payload.data}
      case FETCH_GALLERY_FAIL:
        return {loading:false, error:action.payload}
      default:
        return state;      
    }
  }

  export const deleteGalleryReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_GALLERY_REQUEST:
        return { loading: true };
      case DELETE_GALLERY_SUCCESS:
        return { loading: false, success: true ,response:action.payload};
      case DELETE_GALLERY_FAIL:
        return { loading: false, error: action.payload };
      case DELETE_GALLERY_RESET:
        return {};
      default:
        return state;
    }
  };