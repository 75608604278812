import axios from "axios";
import {
  SPA_CREATE_FAIL,
  SPA_CREATE_REQUEST,
  SPA_CREATE_SUCCESS,
  DELETE_SPA_FAIL,
  DELETE_SPA_REQUEST,
  DELETE_SPA_SUCCESS,
  FETCH_SPA_FAIL,
  FETCH_SPA_REQUEST,
  FETCH_SPA_SUCCESS,
  Url,
  FETCH_SPA_DETAILS_REQUEST,
  EDIT_SPA_REQUEST,
  FETCH_SPA_DETAILS_SUCCESS,
  EDIT_SPA_SUCCESS,
  EDIT_SPA_FAIL,
  FETCH_SPA_DETAILS_FAIL,
} from "../constants/Spa";

export const addSpa = (serviceTitle,image,categories,note) => async (dispatch) => {
  dispatch({ type: SPA_CREATE_REQUEST });
  try {
    const { data } = await axios.post(
      `${Url}/api/v1/service`,
      {serviceTitle,image,categories,note},
      {
        headers: {'Content-Type': 'application/json'},
      }
    );
    console.log(data, "dataaaaaaaaaa");
    dispatch({ type: SPA_CREATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: SPA_CREATE_FAIL, payload: message });
  }
};

export const getSpa = () => async (dispatch) => {
  dispatch({ type: FETCH_SPA_REQUEST });
  try {
    const { data } = await axios.get(
      `${Url}/api/v1/service`
    );
    dispatch({ type: FETCH_SPA_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: FETCH_SPA_FAIL, payload: message });
  }
};

export const deleteSpa = (id) => async (dispatch) => {
  dispatch({ type: DELETE_SPA_REQUEST });
  try {
    const { data } = await axios.delete(
      `${Url}/api/v1/service/${id}`
    );
    dispatch({ type: DELETE_SPA_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: DELETE_SPA_FAIL, payload: message });
  }
};


export const getSpaById = (id) => async (dispatch) => {
  dispatch({ type: FETCH_SPA_DETAILS_REQUEST });
  try {
    const { data } = await axios.get(`${Url}/api/v1/service/${id}`);
    dispatch({ type: FETCH_SPA_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: FETCH_SPA_DETAILS_FAIL, payload: message });
  }
};

export const editSpaAction = ( updatedData) => async (dispatch) => {
  dispatch({ type: EDIT_SPA_REQUEST });
  try {
    const { data } = await axios.put(
      `${Url}/api/v1/service`,
      updatedData,
  
    );
    dispatch({ type: EDIT_SPA_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: EDIT_SPA_FAIL, payload: message });
  }
};
