export const BANNER_CREATE_REQUEST ='BANNER_CREATE_REQUEST'
export const BANNER_CREATE_SUCCESS ='BANNER_CREATE_SUCCESS'
export const BANNER_CREATE_FAIL ='BANNER_CREATE_FAIL'
export const BANNER_CREATE_RESET ='BANNER_CREATE_RESET'


export const FETCH_BANNER_REQUEST ='FETCH_BANNER_REQUEST'
export const FETCH_BANNER_SUCCESS ='FETCH_BANNER_SUCCESS'
export const FETCH_BANNER_FAIL ='FETCH_BANNER_FAIL'

export const DELETE_BANNER_REQUEST ='DELETE_BANNER_REQUEST'
export const DELETE_BANNER_SUCCESS ='DELETE_BANNER_SUCCESS'
export const DELETE_BANNER_FAIL ='DELETE_BANNER_FAIL'
export const DELETE_BANNER_RESET='DELETE_BANNER_RESET'
