import axios from "axios";
import { Url } from "../constants/Blog";
import { GALLERY_CREATE_FAIL, GALLERY_CREATE_REQUEST, GALLERY_CREATE_SUCCESS, DELETE_GALLERY_FAIL, DELETE_GALLERY_REQUEST, DELETE_GALLERY_SUCCESS, FETCH_GALLERY_FAIL, FETCH_GALLERY_REQUEST, FETCH_GALLERY_SUCCESS} from "../constants/Gallery";

export const addGallery =(category,image)=>async(dispatch)=>{
    dispatch({type:GALLERY_CREATE_REQUEST});
    try{
      const {data}= await axios.post(`${Url}/api/v1/gallery`,{category,image},{
        headers:{'Content-Type': 'application/json'}  
      })
      dispatch({type:GALLERY_CREATE_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: GALLERY_CREATE_FAIL, payload: message })
    }
  }

  export const getGallery =()=>async(dispatch)=>{
    dispatch({type:FETCH_GALLERY_REQUEST});
    try{
      const {data}= await axios.get(`${Url}/api/v1/gallery`)
      dispatch({type:FETCH_GALLERY_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: FETCH_GALLERY_FAIL, payload: message })
    }
  }

  export const deleteGallery =(id)=>async(dispatch)=>{
    dispatch({type:DELETE_GALLERY_REQUEST});
    try{
      const {data}= await axios.delete(`${Url}/api/v1/gallery/${id}`)
      dispatch({type:DELETE_GALLERY_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: DELETE_GALLERY_FAIL, payload: message })
    }
  }